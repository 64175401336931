import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Input, Modal, message } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image } from '../../../models/media.model';
import { useUploadImageMutation } from '../../../services/media.service';
import {
  selectImages,
  selectTotal,
  updateImagesList,
} from '../../../store/image.slice';
import getSetBase64 from '../../../utils/getSetBase64';
import { LIMIT_IMAGE_PER_PAGE } from '../../../utils/globalVariable';
import LinksContainer from '../../commons/LinksContainer';
import FormLabel from '../../form/FormLabel';
import Button from '../MediaButton';
import MediaContent from '../MediaContent';
import Upload from '../Upload';
import EditImageInfo from './EditImageInfo';

interface PropsType {
  selectedImage: Image;
  setSelectedImage: (value: Image) => void;
  getUrl: (value: string) => void;
  closeModal: () => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const acceptTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
const checkFileType = (type: string) => {
  return acceptTypes.includes(type);
};

const UserImages = ({
  selectedImage,
  setSelectedImage,
  getUrl,
  closeModal,
  currentPage,
  setCurrentPage,
}: PropsType): ReactElement => {
  const images = useSelector(selectImages);
  const total = useSelector(selectTotal);
  const [isVisbleModal, setIsVisibleModal] = useState(false);
  const dispatch = useDispatch();
  const [uploadImage] = useUploadImageMutation();
  // const [deleteImage] = useDeleteImageMutation();
  // const { state } = useContext(EditorContext);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [tmpImage, setTmpImage] = useState<RcFile>({} as RcFile);
  const [base64, setBase64] = useState<string | ArrayBuffer | null>('');

  // const editorView = state ? state.editorView : undefined;

  const beforeUpload = (file: RcFile) => {
    if (checkFileType(file.type)) {
      setTmpImage(file);
      getSetBase64(file, setBase64);
      setShowModal(true);
    } else {
      message.error(`${file.name} không phải định dạng cho phép upload`);
    }
    return checkFileType(file.type) ? false : Upload.LIST_IGNORE;
  };
  const onCancel = () => {
    setShowModal(false);
    setBase64(null);
    form.resetFields();
  };
  const handleUploadImage = () => {
    const formData = new FormData();
    formData.append('upload', tmpImage);
    formData.append('title', form.getFieldValue('title').trim());
    uploadImage({ data: formData })
      .unwrap()
      .then((image: Image) => {
        message.success('Upload hình ảnh mới thành công!');
        dispatch(updateImagesList(image));
        setSelectedImage(image);
      })
      .catch((error) => {
        message.error(error.data);
        console.log('Error: ', error.data);
      });
  };
  const onFinish = () => {
    if (!uploadImage || !tmpImage) return;
    form
      .validateFields()
      .then(() => {
        handleUploadImage();
        onCancel();
      })
      .catch((error) => {
        message.error(error.data);
        console.log('Error', error.data);
      });
  };
  // const showDeleteConfirm = () => {
  //   confirm({
  //     title: 'Bạn có chắc chắn muốn xoá ảnh này?',
  //     icon: <ExclamationCircleFilled />,
  //     content: '',
  //     okText: 'Xác nhận',
  //     okType: 'danger',
  //     cancelText: 'Huỷ',
  //     onOk() {
  //       deleteImage({ id: selectedImage.id })
  //         .unwrap()
  //         .then(() => {
  //           message.success('Xoá ảnh thành công!');
  //           dispatch(removeImageFromList(selectedImage.id));
  //           setSelectedImage({} as Image);
  //         })
  //         .catch((error) => {
  //           message.error(error.data);
  //           console.log('Error: ', error.data.error);
  //         });
  //     },
  //     onCancel() {
  //       console.log('Cancel');
  //     },
  //   });
  // };

  const handleChoseImage = () => {
    getUrl(selectedImage.url);
    closeModal();
  };

  return (
    <>
      <GroupButton>
        <LinksContainer style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            // onChange={handleUploadImage}
          >
            <Button.Warning>
              <UploadOutlined /> Tải ảnh lên
            </Button.Warning>
          </Upload>

          <Button.Primary
            disabled={!selectedImage.id}
            onClick={handleChoseImage}
          >
            <CheckOutlined /> Chọn ảnh
          </Button.Primary>
          {/* <Button.Primary disabled={!selectedImage.id} onClick={() => setIsVisibleModal(true)}>
            <EditOutlined /> Sửa thông tin ảnh
          </Button.Primary> */}
        </LinksContainer>

        {/* <LinksContainer>
          <Button.Danger disabled={!selectedImage.id} onClick={showDeleteConfirm}>
            <DeleteFilled /> Xoá ảnh
          </Button.Danger>
        </LinksContainer> */}
      </GroupButton>
      <MediaContent
        images={images}
        total={total * LIMIT_IMAGE_PER_PAGE}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        currentPage={currentPage}
        setCurrentPage={(value) => setCurrentPage(value)}
      />

      {isVisbleModal && (
        <EditImageInfo
          isModalVisible={isVisbleModal}
          closeModal={() => setIsVisibleModal(false)}
          selectedImage={selectedImage}
        />
      )}
      {showModal && (
        <Modal
          visible={showModal}
          keyboard={true}
          width={400}
          footer={null}
          onCancel={onCancel}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item style={{ paddingTop: '26px' }}>
              <img
                alt="example"
                style={{ width: '100%' }}
                src={typeof base64 === 'string' ? base64 : undefined}
              />
            </Form.Item>
            <Form.Item
              label={<FormLabel label="Title (Description)" require={true} />}
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên ảnh!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button.Primary type="primary" htmlType="submit">
              Save
            </Button.Primary>
          </Form>
        </Modal>
      )}
    </>
  );
};
export default UserImages;
