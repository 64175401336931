import { ColumnsType } from 'antd/lib/table';
import { Post } from 'models/blog.model';
import { ReactElement, useEffect, useState } from 'react';
import { Input, Pagination, Table, Select } from 'antd';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import TableContainer from 'components/table/TableContainer';
import { blogStatusValues } from 'libraries/utils/globalVariable';
import { Tag } from 'models/tag.model';

const { Option } = Select;

interface KeySearchType {
  title: string;
  status: string;
  order: string;
  direction: 'asc' | 'desc';
  tagId: string;
}

interface PropsType {
  posts: Post[];
  total_page: number;
  currentPage: number;
  tags: Tag[];
  setCurrentPage: (value: number) => void;
  setKeySearch: (value: string) => void;
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
  handleOrderList: (order: string, direction: 'asc' | 'desc') => void;
}

const ListPostsTable = ({
  posts,
  total_page,
  currentPage,
  tags,
  setCurrentPage,
  setKeySearch,
  state,
  handleSetState,
  handleOrderList,
}: PropsType): ReactElement => {
  const [tmpTitle, setTmpTitle] = useState('');
  const { title, status, order, direction, tagId } = state;

  const handleOnchangePage = (page: number) => {
    setCurrentPage(page);
    setKeySearch(
      `page=${page}&title=${title}&status=${status}&order=${order}&tagId=${tagId}&type=normal&direction=${direction}`,
    );
  };

  useEffect(() => {
    const { title, status, order, direction } = state;
    setCurrentPage(1);
    setKeySearch(
      `page=1&title=${title}&status=${status}&order=${order}&tagId=${tagId}&type=normal&direction=${direction}`,
    );
    if (title === '') setTmpTitle('');
  }, [state]);

  const columns: ColumnsType<Post> = [
    {
      width: '30%',
      sorter: true,
      showSorterTooltip: false,
      title: (
        <div
          onClick={() => {
            handleOrderList('title', direction === 'desc' ? 'asc' : 'desc');
          }}
        >
          Tiêu đề
        </div>
      ),
      children: [
        {
          key: 'title',
          dataIndex: 'title',
          title: (
            <Input
              value={tmpTitle}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('title', tmpTitle);
                }
              }}
              onChange={(e) => setTmpTitle(e.target.value)}
            />
          ),
          render: (_text, item) => {
            return (
              <RouterLink
                to={generatePath(route.blog.detail, { postId: item.id })}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {item.title}
              </RouterLink>
            );
          },
        },
      ],
    },
    {
      width: '15%',
      title: 'Danh mục',
      children: [
        {
          key: 'tags',
          dataIndex: 'tags',
          title: (
            <Select
              showSearch
              optionFilterProp="children"
              dropdownMatchSelectWidth={150}
              value={tagId}
              onChange={(value) => handleSetState('tagId', value)}
            >
              <Option value="">Tất cả</Option>
              {tags.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          ),
          render: (_text, item) => {
            const result = [];
            for (let i = 0; i < item.tags.length; i++) {
              const tag = item.tags[i];
              for (let j = 0; j < tags.length; j++) {
                const item = tags[j];
                if (item.id === tag.id) {
                  result.push(item.name);
                  break;
                }
              }
            }
            return (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {result.length > 0 &&
                  result.map((item, index) => {
                    if (index < result.length - 1) return `${item}, `;
                    else return item;
                  })}
              </div>
            );
          },
        },
      ],
    },
    {
      width: '15%',
      title: 'Trạng thái',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={status}
              onChange={(value) => handleSetState('status', value)}
            >
              <Option value="">Tất cả</Option>
              {blogStatusValues.map((item, index) => (
                <Option value={item.value} key={index}>
                  {item.label}
                </Option>
              ))}
            </Select>
          ),
          render: (status) => {
            if (status !== '') {
              for (let i = 0; i < blogStatusValues.length; i++) {
                const item = blogStatusValues[i];
                if (item.value === status) {
                  return item.label;
                }
              }
            } else {
              return '';
            }
          },
        },
      ],
    },
    {
      width: '15%',
      title: (
        <div
          onClick={() => {
            handleOrderList(
              'created_at',
              direction === 'desc' ? 'asc' : 'desc',
            );
          }}
        >
          Ngày tạo
        </div>
      ),
      children: [
        {
          key: 'created_at',
          dataIndex: 'created_at',
          render: (created_at) =>
            created_at !== '' ? formatDate(created_at) : '',
        },
      ],
      sorter: true,
      showSorterTooltip: false,
    },
  ];

  return (
    <TableContainer>
      <Table<Post>
        columns={columns}
        dataSource={posts}
        pagination={false}
        rowKey="id"
        bordered
      />
      <Pagination
        current={currentPage}
        pageSize={10}
        showSizeChanger={false}
        defaultCurrent={currentPage}
        total={total_page * 10}
        style={{ marginTop: '20px' }}
        onChange={handleOnchangePage}
      />
    </TableContainer>
  );
};

export default ListPostsTable;
