import { Table } from 'antd';
import Item from 'antd/lib/list/Item';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { PayOrderReport, ReceiptOrderReport } from 'models/report.model';
import React, { ReactElement } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { formatDate } from 'utils/formatDate';
import { v4 } from 'uuid';

interface PropsType {
  reports: ReceiptOrderReport[];
}
type TableData = ReceiptOrderReport & { key: React.Key };

const OrderReceiptTable = ({ reports }: PropsType): ReactElement => {
  const tableData: TableData[] = reports.map((report) => ({
    ...report,
    key: v4(),
  }));
  const columns: ColumnsType<TableData> = [
    {
      key: 'order_id',
      width: '10%',
      title: 'Mã đơn hàng',
      dataIndex: 'order_id',
      render: (order_id) => (
        <RouterLink
          to={generatePath(route.order.detail, { orderId: order_id })}
        >
          {order_id}
        </RouterLink>
      ),
    },
    {
      key: 'datetime',
      width: '10%',
      title: 'Ngày giao dịch',
      dataIndex: 'datetime',
      render: (datetime) => (datetime !== '' ? formatDate(datetime) : ''),
    },
    {
      key: 'user_id',
      width: '15%',
      title: 'Người nộp tiền',
      dataIndex: 'user_id',
      render: (_text, item) => (
        <RouterLink
          to={generatePath(route.user.info, { userId: item.user_id })}
        >
          {item.full_name}
        </RouterLink>
      ),
    },
    {
      key: 'amount',
      width: '10%',
      title: 'Số tiền đã đóng',
      dataIndex: 'amount',
      render: (amount) => (amount && amount !== 0 ? formatMoney(amount) : 0),
      className: 'text-right',
    },
    {
      key: 'total',
      width: '10%',
      title: 'Tổng tiền đơn hàng',
      dataIndex: 'total',
      render: (total) => (total && total !== 0 ? formatMoney(total) : 0),
      className: 'text-right',
    },
    {
      key: 'items',
      width: '25%',
      title: 'Items',
      dataIndex: 'items',
      render: (items) => {
        return items ? (
          <>
            {items.map((i: string, index: number) => (
              <div
                key={index}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {i}
              </div>
            ))}
          </>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'note',
      width: '20%',
      title: 'Ghi chú',
      dataIndex: 'note',
      render: (note) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {note}
        </div>
      ),
    },
    {
      key: 'receiver_full_name',
      width: '20%',
      title: 'Người nhận tiền',
      dataIndex: 'receiver_full_name',
      render: (receiver_full_name) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {receiver_full_name}
        </div>
      ),
    },
  ];

  return (
    <TableContainer>
      <Table<TableData>
        columns={columns}
        dataSource={tableData}
        pagination={{ showSizeChanger: false }}
        bordered
      />
    </TableContainer>
  );
};

export default OrderReceiptTable;
