import { CalendarOutlined } from '@ant-design/icons';
import { Dropdown, Input, Result } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { ComponentContent } from 'components/commons/ComponentContent';
import Tabs from 'components/commons/Tabs';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { selectSale } from 'store/report.slice';
import styled from 'styled-components';
import DropdownMenu from 'components/report/DropdownMenu';
import OpenedClassReport from './opened-class/OpenedClassReport';
import OrdersReport from './reports/OrdersReport';
import PostsReport from './reports/PostsReport';
import { useDateRange } from 'libraries/hooks/date';
import {
  useGetFinanceReportQuery,
  useGetSaleSalaryInfoQuery,
  useGetUserBonusSalaryReportQuery,
  useGetBaseSalaryQuery,
} from 'services/report.service';
import ServerError from 'components/commons/ServerError';
import CollectedReceiptsReport from './reports/CollectedReceiptsReport';
import BonusRequestTable from './tables/BonusRequestTable';
import { selectUser } from 'store/auth.slice';
import BonusSalaryTable from './tables/BonusSalaryTable';
import ExportSaleExcel from './ExportSaleExcel';
import { formatDate } from 'utils/formatDate';
import OrderPaymentReport from './reports/OrderPaymentReport';
import { ListPayReceiptType, SalaryInfos } from 'models/report.model';
import { BonusDetails } from 'models/report.model';
import SalaryReport from './reports/SalaryReport';
import LoadingSpin from '../../../../libraries/components/LoadingSpin';
import moment from 'moment';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { TabPane } = Tabs;

const SaleDetailContent = (): ReactElement => {
  const history = useHistory();
  const location = useLocation(); // Thêm dòng này
  const sale = useSelector(selectSale);
  const { id: userId, roles: userRoles } = useSelector(selectUser);

  const [visible, setVisible] = useState(false);
  const [isDateChange, setIsDateChange] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [firstDayHref, setFirstDayHref] = useState<any>();
  const [lastDayHref, setLastDayHref] = useState<any>();

  const search = location.search; // Sửa dòng này
  const searchParams = new URLSearchParams(location.search);
  const bonusId = sale.id;
  useEffect(() => {
    if (bonusId !== null) {
      setActiveKey(() => (userRoles.includes(1) ? '7' : '1'));
    }
  }, [bonusId]);

  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange(
    firstDayHref,
    lastDayHref,
  );
  useEffect(() => {
    if (searchParams.get('from') == null || !searchParams.get('to') == null)
      return;
    setFirstDayHref(searchParams.get('from'));
    setLastDayHref(searchParams.get('to'));
  }, [searchParams]);
  useEffect(() => {
    if (!isDateChange) return;
    searchParams.set('from', firstDay.toISOString());
    searchParams.set('to', lastDay.toISOString());
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [firstDay, lastDay]);

  const { data, isError, isLoading } = useGetFinanceReportQuery({
    from: firstDayHref || firstDay.toISOString(),
    to: lastDayHref || lastDay.toISOString(),
  });

  const {
    data: salaries,
    isLoading: isSalariesLoading,
    isError: isSalariesError,
  } = useGetSaleSalaryInfoQuery({
    id: userId,
    from: firstDayHref || firstDay.toISOString(),
    to: lastDayHref || lastDay.toISOString(),
  });

  const {
    data: bonus,
    isLoading: isBonusLoading,
    isError: isBonusError,
  } = useGetUserBonusSalaryReportQuery({
    id: bonusId,
    from: firstDayHref || firstDay.toISOString(),
    to: lastDayHref || lastDay.toISOString(),
  });

  const { data: basePercentage, isError: isBpError } = useGetBaseSalaryQuery();
  if (isError || isBpError) {
    return <ServerError />;
  }
  const bonusProps = {
    bonus: bonus ? [...bonus] : ([] as BonusDetails[]),
    firstDay,
    lastDay,
    isLoading: isBonusLoading,
    isError: isBonusError,
  };
  const exportSaleExcelProps = {
    name: sale.full_name,
    receipts: data ? { ...data } : ({} as ListPayReceiptType),
    isError,
    isLoading,
    firstDay: formatDate(firstDayHref || firstDay.toISOString()),
    lastDay: formatDate(lastDayHref || lastDay.toISOString()),
    bonuses: bonus ? [...bonus] : ([] as BonusDetails[]),
    basePercentage: basePercentage ? basePercentage[0].percent_finance : 0,
    isBpError,
  };

  if (isLoading || isBonusLoading || isSalariesLoading) return <LoadingSpin />;
  if (isError || isBpError || isSalariesError) return <ServerError />;
  const handleInputChange = (event: any) => {
    setIsDateChange(true);
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.report.sale.list)}
        secondaryText="Nhân viên tuyển sinh"
        text={sale.full_name}
      />
      <GroupButton>
        <LinksContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginTop: '5px' }}>
            <Dropdown
              overlay={
                <DropdownMenu
                  setVisible={setVisible}
                  firstDay={firstDay}
                  lastDay={lastDay}
                  setFirstDay={setFirstDay}
                  setLastDay={setLastDay}
                />
              }
              placement="bottomLeft"
              arrow
              visible={visible}
              trigger={['click']}
              onVisibleChange={(flag: boolean) => setVisible(flag)}
            >
              <Input
                style={{ width: 250 }}
                addonBefore={<CalendarOutlined />}
                // value={`${format(firstDay)} - ${format(lastDay)}`}
                value={
                  firstDayHref != lastDayHref
                    ? `${moment(firstDayHref).format('DD/MM/YYYY')} - ${moment(
                        lastDayHref,
                      ).format('DD/MM/YYYY')}  `
                    : `${format(firstDay)} - ${format(lastDay)}`
                }
                onClick={handleInputChange}
              />
            </Dropdown>
          </div>
          <ExportSaleExcel {...exportSaleExcelProps} />
        </LinksContainer>
      </GroupButton>

      <ComponentContent>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        >
          <TabPane tab="Lớp mở trong tháng" key="1">
            <OpenedClassReport firstDay={firstDay} lastDay={lastDay} />
          </TabPane>

          <TabPane tab="Đơn đã thu" key="2">
            <CollectedReceiptsReport
              data={data ? { ...data } : ({} as ListPayReceiptType)}
            />
          </TabPane>

          <TabPane tab="Đơn đã hoàn" key="3">
            <OrderPaymentReport data={data?.pay_for_order ?? []} />
          </TabPane>
          {/* 
          <TabPane tab="Đơn online" key="4">
            <OrdersReport firstDay={firstDay} lastDay={lastDay} />
          </TabPane> */}

          <TabPane tab="Bài viết" key="5">
            <PostsReport firstDay={firstDay} lastDay={lastDay} />
          </TabPane>

          {userId === sale.id && (
            <TabPane tab="Yêu cầu thưởng" key="6">
              <BonusRequestTable {...bonusProps} />
            </TabPane>
          )}
          {userRoles.includes(1) && (
            <TabPane tab="Duyệt thưởng " key="7">
              <BonusSalaryTable
                bonus={bonus ? [...bonus] : ([] as BonusDetails[])}
                isLoading={isBonusLoading}
                isError={isBonusError}
                userId={sale.id}
              />
            </TabPane>
          )}
          {userId === sale.id && (
            <TabPane tab="Tổng kết lương" key="8">
              <SalaryReport
                data={salaries ? { ...salaries } : ({} as SalaryInfos)}
                basePercentage={
                  basePercentage ? basePercentage[0].percent_finance : 0
                }
                bonus={bonus ? [...bonus] : ([] as BonusDetails[])}
              />
            </TabPane>
          )}
        </Tabs>
      </ComponentContent>
    </>
  );
};

export default SaleDetailContent;
