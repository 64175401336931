import { ReactElement } from 'react';
import ReactExport from 'react-data-export';
import styled from 'styled-components';

interface PropsType {
  firstDay: string;
  lastDay: string;
}

const Anchor = styled('a')`
  display: inline-block;
  font-weight: 600;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  color: #fff;
  background-color: #3c8dbc;
  border-color: #367fa9;
  &:hover,
  &:active {
    color: #fff;
    background-color: #367fa9;
    border-color: #367fa9;
  }
`;

const ExportExcel = ({ firstDay, lastDay }: PropsType): ReactElement => {
  return (
    <Anchor
      href={
        process.env.REACT_APP_API_DOMAIN +
        'percent_revenue?from=' +
        firstDay +
        '&to=' +
        lastDay
      }
    >
      Xuất Excel
    </Anchor>
  );
};

export default ExportExcel;
