import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import ServerError from 'components/commons/ServerError';
import { produce } from 'immer';
import Link from 'libraries/components/commons/Link';
import LoadingSpin from 'libraries/components/LoadingSpin';
import {
  ClassStudentLessonStatus,
  StudentRank,
  UserExercises,
  UserLearnProcess,
} from 'models/user.model';
import type { FC, Key } from 'react';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import ReactExport from 'react-data-export';
import { generatePath, Link as Links, useParams } from 'react-router-dom';
import { useGetStudentsRankingQuery } from 'services/classes.service';
import styled from 'styled-components';
import { route } from '../../../../../routers/routeNames';
import { useGetStudentInfoQuery } from '../../../../../services/user.service';
import useQuery from '../../../../../libraries/hooks/query';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface DataSource {
  total_lessons: number;
  attend: number;
  skip: number;
  total_exercises: number;
  submit: number;
  not_submit: number;
  class_name: string;
  key: Key;
  average: number;
  rank: number;
}
const LearnOutcomesMobileContainer = styled.div`
  display: flex;
  gap: 60px;
`;
const LearnOutcomesMobile = (dataSource: any): ReactElement => {
  const { dataSource: student } = dataSource;
  return (
    <>
      <LearnOutcomesMobileContainer>
        <div className="learnOutcomes-left">
          <p>Tên lớp:</p>
          <p>Tổng số buổi học:</p>
          <p>Tổng số bài tập:</p>
          <p>Đi học:</p>
          <p>Nghỉ học:</p>
          <p>Đã nộp:</p>
          <p>Không nộp:</p>
          <p>Điểm trung bình:</p>
          <p>Xếp hạng:</p>
        </div>
        <div className="learnOutcomes-right">
          <p>{student[0]?.class_name}</p>
          <p>{student[0]?.total_lessons}</p>
          <p>{student[0]?.total_exercises}</p>
          <p>{student[0]?.attend}</p>
          <p>{student[0]?.skip}</p>
          <p>{student[0]?.submit}</p>
          <p>{student[0]?.not_submit}</p>
          <p>{student[0]?.average}</p>
          <p>{student[0]?.rank}</p>
        </div>
      </LearnOutcomesMobileContainer>
    </>
  );
};

const LearnProcessStudent: FC = () => {
  const [currentClassId, setCurrentClassId] = useState('');
  const [rankArr, setRankArr] = useState<StudentRank[]>([]);
  const { classId, studentId } = useParams<{
    classId: string;
    studentId: string;
  }>();
  const { userId } = useParams<{ userId: string }>();
  const searchParams = useQuery();
  const code = searchParams.get('code') ?? '';
  const {
    data: studentRanking,
    isLoading: isStudentRankingLoading,
    isError: isStudentRankingError,
    error: studentRankingError,
    isFetching: isStudentRankingFetching,
  } = useGetStudentsRankingQuery(
    { class_id: currentClassId, student_id: userId, code: '' },
    {
      skip: currentClassId === '',
    },
  );
  const { learn_process = [] as UserLearnProcess[] } = useGetStudentInfoQuery(
    { student_id: studentId, class_id: classId },
    {
      selectFromResult: ({ data }) => ({
        learn_process: data?.learn_process,
      }),
    },
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!studentRanking) return;
    setRankArr(
      produce((draft) => {
        draft.push(studentRanking);
      }),
    );
  }, [studentRanking]);

  useEffect(() => {
    return () => {
      setRankArr([]);
      setCurrentClassId('');
    };
  }, []);

  useEffect(() => {
    const index = learn_process.findIndex(
      ({ class_id }) => class_id === currentClassId,
    );
    if (index < 0 && !isStudentRankingFetching) {
      setCurrentClassId(learn_process[0]?.class_id);
    }
    if (index + 1 < learn_process.length && !isStudentRankingFetching) {
      setCurrentClassId(learn_process[index + 1].class_id);
    }
  }, [learn_process, studentRanking]);

  const dataSource = useMemo(
    () =>
      learn_process.map((item: any, index: number) => {
        return getDataSource({
          learn_process: item,
          average: rankArr[index]?.average,
          rank: rankArr[index]?.rank,
        });
      }),
    [learn_process, rankArr],
  );
  return (
    <div style={{ padding: '0px 0' }}>
      {isStudentRankingError ? (
        <ServerError
          title={`Status ${
            !!('originalStatus' in studentRankingError) &&
            studentRankingError.originalStatus
          }: ${
            !!('status' in studentRankingError) && studentRankingError.status
          },${
            !!('error' in studentRankingError) && studentRankingError.error
          } `}
        />
      ) : isStudentRankingLoading ? (
        <LoadingSpin />
      ) : (
        <>
          <div style={{ marginBottom: '24px' }}>
            <ExcelFile
              element={<Link.Primary to="#">Xuất Excel</Link.Primary>}
              filename={`Kết quả học tập của học viên`}
            >
              <ExcelSheet data={dataSource} name="Danh sách học viên">
                <ExcelColumn label="Tên lớp" value="class_name" />
                <ExcelColumn label="Tổng số buổi học" value="total_lessons" />
                <ExcelColumn label="Tổng số bài tập" value="total_exercises" />
                <ExcelColumn label="Đi học" value="attend" />
                <ExcelColumn label="Nghỉ học" value="skip" />
                <ExcelColumn label="Đã nộp" value="submit" />
                <ExcelColumn label="Không nộp" value="not_submit" />
                <ExcelColumn label="Điểm TB" value="average" />
                <ExcelColumn label="Xếp hạng" value="rank" />
              </ExcelSheet>
            </ExcelFile>
          </div>
          {isMobile ? (
            <LearnOutcomesMobile dataSource={dataSource} />
          ) : (
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          )}
        </>
      )}
    </div>
  );
};

//fn & util
const getAttendanceNumber = (arg: ClassStudentLessonStatus[]) => {
  if (arg.length === 0) {
    return { attend: 0, online: 0, skip: 0 };
  }
  const record = arg.reduce(
    (acc, curr) => {
      if (curr.status) {
        acc.attend += 1;
      } else if (curr.status_online) {
        acc.online += 1;
      }
      return acc;
    },
    { attend: 0, online: 0 },
  );
  return { ...record, skip: arg.length - record.attend - record.online };
};
const getTotalSubmit = (arg: UserExercises[]) => {
  if (arg.length === 0) return 0;
  return arg.reduce((acc, curr) => {
    if (curr.status_submit) acc += 1;
    return acc;
  }, 0);
};
const getDataSource = ({
  learn_process,
  average,
  rank,
}: {
  learn_process: UserLearnProcess;
  average: number;
  rank: number;
}): DataSource => {
  const { class_name, class_id, total_lessons } = learn_process;
  const status_lessons =
    learn_process.status_lessons ?? ([] as ClassStudentLessonStatus[]);
  const exercises = learn_process.exercises ?? ([] as UserExercises[]);
  const { attend, skip } = getAttendanceNumber(status_lessons);
  const submit = getTotalSubmit(exercises);
  return {
    attend,
    skip,
    submit,
    total_exercises: exercises.length,
    not_submit: exercises.length - submit,
    average,
    rank,
    class_name,
    key: class_id,
    total_lessons,
  };
};

const columns: TableColumnsType<DataSource> = [
  {
    dataIndex: 'class_name',
    key: 'class_name',
    title: 'Tên lớp',
    render: (class_name, { key }) => (
      <Links
        to={generatePath(route.class.detail, { classId: key })}
        style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
      >
        {class_name}
      </Links>
    ),
  },
  {
    dataIndex: 'total_lessons',
    key: 'total_lessons',
    title: 'Tổng số buổi học',
    align: 'center',
  },
  {
    dataIndex: 'total_exercises',
    key: 'total_exercises',
    title: 'Tổng số bài tập',
    align: 'center',
  },
  {
    dataIndex: 'attend',
    key: 'attend',
    title: 'Đi học',
    align: 'center',
  },
  {
    dataIndex: 'skip',
    key: 'skip',
    title: 'Nghỉ học',
    align: 'center',
  },
  {
    dataIndex: 'submit',
    key: 'submit',
    title: 'Đã nộp',
    align: 'center',
  },
  {
    dataIndex: 'not_submit',
    key: 'not_submit',
    title: 'Không nộp',
    align: 'center',
  },
  {
    dataIndex: 'average',
    key: 'average',
    title: 'Điểm TB',
    align: 'center',
  },
  {
    dataIndex: 'rank',
    key: 'rank',
    title: 'Xếp hạng',
    align: 'center',
  },
];

export default LearnProcessStudent;
