import Link from 'libraries/components/commons/Link';
import { formatMoney } from 'libraries/utils/changeFormatData';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { TeacherAttendanceSalary } from 'models/report.model';
import { ReactElement } from 'react';
import ReactExport from 'react-data-export';
import { BonusDetails } from 'models/report.model';

interface PropsType {
  name: string;
  attendances: TeacherAttendanceSalary[];
  firstDay: string;
  lastDay: string;
  bonuses: BonusDetails[];
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({
  name,
  attendances,
  firstDay,
  lastDay,
  bonuses,
}: PropsType): ReactElement => {
  const approvedBonuses = bonuses.filter((bonus) => bonus.status === 'approve');
  const totalSalary = attendances.reduce(
    (prev, cur) => (prev += cur.salary),
    0,
  );
  const totalBonus = approvedBonuses.reduce(
    (prev, cur) => (prev += cur.bonus),
    0,
  );
  return (
    <ExcelFile
      element={
        <Link.Primary to="#" style={{ height: 32, lineHeight: 'normal' }}>
          Xuất Excel
        </Link.Primary>
      }
      filename={`Báo cáo chi tiết thu nhập của giảng viên ${name} từ ${firstDay} đến ${lastDay}`}
    >
      <ExcelSheet data={attendances} name="Dạy học">
        <ExcelColumn
          label="Ngày điểm danh"
          value={(col: TeacherAttendanceSalary) =>
            col.date !== '' ? formatDate(col.date) : ''
          }
        />
        <ExcelColumn label="Lớp học" value="class_name" />
        <ExcelColumn label="Số học viên đi học/ Tổng số" value="class_size" />
        <ExcelColumn
          label="Lợi nhuận (đ)"
          value={(col: TeacherAttendanceSalary) =>
            col.profit !== 0 ? col.profit : 0
          }
        />
        <ExcelColumn
          label="Lương (đ)"
          value={(col: TeacherAttendanceSalary) =>
            col.salary !== 0 ? col.salary : 0
          }
        />
        <ExcelColumn
          label="Ghi chú"
          value={(col: TeacherAttendanceSalary) => {
            if (col.class_type !== '') {
              for (let i = 0; i < classTypeValues.length; i++) {
                const item = classTypeValues[i];
                if (item.value === col.class_type) {
                  return item.label;
                }
              }
            }
            return '';
          }}
        />
      </ExcelSheet>
      <ExcelSheet data={approvedBonuses} name="Lương thưởng ">
        <ExcelColumn
          label="Ngày thực hiện"
          value={(col: BonusDetails) =>
            col.date !== '' ? formatDate(col.date) : ''
          }
        />
        <ExcelColumn label="Đầu việc" value="content" />
        <ExcelColumn
          label="Lương thưởng (đ)"
          value={(col: BonusDetails) => (col.bonus !== 0 ? col.bonus : 0)}
        />
      </ExcelSheet>
      <ExcelSheet data={Array(1).fill(null)} name="Tổng">
        <ExcelColumn label="Tổng lương" value={() => totalSalary} />
        <ExcelColumn label="Tổng thưởng" value={() => totalBonus} />

        <ExcelColumn
          label="Thưởng + Lương"
          value={() => totalBonus + totalSalary}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportExcel;
