import { LeftOutlined } from '@ant-design/icons';
import { Col, Input, message, Row, Select } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import {
  courseActiveValues,
  courseStatusValues,
  ROLE_SALE,
  ROLE_TRAINER,
} from 'libraries/utils/globalVariable';
import { ReactElement, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import {
  useCreateNewCourseMutation,
  useGetTagsQuery,
} from 'services/course.service';
import { useGetUsersByRoleQuery } from 'services/user.service';
import Editor from 'libraries/editor';
import parser from 'libraries/editor/parser';
import Hotkeys from 'react-hot-keys';

const { TextArea } = Input;
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const CreateCourseContent = (): ReactElement => {
  const { data: tags } = useGetTagsQuery();
  const { data: teachers } = useGetUsersByRoleQuery(ROLE_TRAINER);
  const { data: salers } = useGetUsersByRoleQuery(ROLE_SALE);
  const [createNewCourse] = useCreateNewCourseMutation();
  const history = useHistory();

  const [chosenThumbnail, setChosenThumbnail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    createNewCourse({
      name: values.name,
      is_interactive: values.is_interactive === 'true' ? true : false,
      status: values.status,
      description: parser.render(values.description),
      short_description: values.short_description,
      thumbnail: chosenThumbnail,
      tag_ids: values.tag_ids !== undefined ? values.tag_ids : [],
      sale_id: values.sale_id,
      teachers: values.teachers,
    })
      .unwrap()
      .then(() => {
        message.success('Tạo khoá học mới thành công!');
        setTimeout(() => {
          history.push(generatePath(route.course.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    handleSubmit(form.getFieldsValue());
  };

  const [form] = Form.useForm();

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.course.list)}
        secondaryText="Tất cả khoá học"
        text="Tạo khoá học"
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            is_interactive: 'true',
            status: 'draft',
          }}
        >
          <LinksContainer>
            <Link.Default to={generatePath(route.course.list)}>
              <LeftOutlined /> Quay lại
            </Link.Default>
            <Button.Primary
              type="primary"
              htmlType="submit"
              style={{ height: '36px' }}
            >
              Tạo khoá học
            </Button.Primary>
          </LinksContainer>
          <BorderTopBoxContent>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={17}>
                <Form.Item
                  label={<FormLabel label="Tên khoá học" />}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập tên khoá học!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Nội dung" />}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Xin vui lòng nhập nội dung khoá học!',
                    },
                  ]}
                >
                  <Editor
                    value={'description'}
                    height={420}
                    onChange={handleChangeDescription}
                  ></Editor>
                </Form.Item>

                <Form.Item
                  name="short_description"
                  label={<FormLabel label="Mô tả ngắn" require={false} />}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
              <Col xs={24} md={7}>
                <Form.Item
                  label={<FormLabel label="Hình thức" require={false} />}
                  name="is_interactive"
                >
                  <Select placeholder="Cách học">
                    {courseActiveValues.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<FormLabel label="Trạng thái" require={false} />}
                  name="status"
                >
                  <Select placeholder="Chọn trạng thái">
                    {courseStatusValues.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {teachers && (
                  <Form.Item
                    label={<FormLabel label="Giảng viên" require={false} />}
                    name="teachers"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Danh sách giảng viên"
                    >
                      {teachers.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.info}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {salers && (
                  <Form.Item
                    label={
                      <FormLabel label="Nhân viên marketing" require={false} />
                    }
                    name="sale_id"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                      placeholder="Danh sách nhân viên"
                    >
                      {salers.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.info}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {tags && (
                  <Form.Item
                    label={<FormLabel label="Danh mục" require={false} />}
                    name="tag_ids"
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                    >
                      {tags.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item label="" name="thumbnail">
                  {chosenThumbnail !== '' && (
                    <img
                      style={{ width: '100%', marginBottom: '20px' }}
                      src={chosenThumbnail}
                    />
                  )}
                </Form.Item>
                <div>
                  <Button.Info onClick={() => setIsModalVisible(true)}>
                    Chọn ảnh khoá học
                  </Button.Info>
                  <p>
                    <em>
                      Chiều rộng: Từ 600px đến 1000px. Tỉ lệ ảnh 1.4. Định dạng
                      jpg hoặc png
                    </em>
                  </p>
                </div>
              </Col>
            </Row>
          </BorderTopBoxContent>
        </Form>
      </Hotkeys>

      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setChosenThumbnail}
        />
      )}
    </>
  );
};
export default CreateCourseContent;
