import { ReactElement, useState } from 'react';
import Breadcrumb from '../../../components/commons/Breadcrumb';
import { useGetBlog20DaysQuery } from '../../../services/blog.service';
import ShowServerError from '../../../components/commons/ShowServerError';
import ListTable from './ListTable';
import { Post20Days } from '../../../models/blog.model';
import { BorderBoxContent } from '../../../components/commons/ComponentContent';
import { Dropdown, Input } from 'antd';
import DropdownMenu from '../../../components/report/DropdownMenu';
import { CalendarOutlined } from '@ant-design/icons';
import { useDateRange } from '../../../libraries/hooks/date';

const GetList = (): ReactElement => {
  const [state, setState] = useState({
    author: '',
    title: '',
    page: 1,
  });
  const [visible, setVisible] = useState(false);
  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange();

  const keySearch =
    'author=' +
    state.author +
    '&title=' +
    state.title +
    '&page=' +
    state.page +
    '&from=' +
    firstDay.toISOString() +
    '&to=' +
    lastDay.toISOString();
  const { data, isError, error } = useGetBlog20DaysQuery(keySearch);

  if (isError) {
    return <ShowServerError error={error} />;
  }
  const handleSetState = (...value: string[]) => {
    const res = {
      [value[0]]: value[1],
    };
    for (let i = 2; i < value.length; i += 2) {
      res[value[i]] = value[i + 1];
    }
    setState({
      ...state,
      ...res,
    });
  };

  return (
    <>
      <Breadcrumb text="Danh sách bài viết" />
      <div style={{ margin: '5px 0 10px 0' }}>
        <Dropdown
          overlay={
            <DropdownMenu
              setVisible={setVisible}
              firstDay={firstDay}
              lastDay={lastDay}
              setFirstDay={setFirstDay}
              setLastDay={setLastDay}
            />
          }
          placement="bottomLeft"
          arrow
          trigger={['click']}
          visible={visible}
          onVisibleChange={(flag: boolean) => setVisible(flag)}
        >
          <Input
            style={{ width: 250 }}
            addonBefore={<CalendarOutlined />}
            value={`${format(firstDay)} - ${format(lastDay)}`}
          />
        </Dropdown>
      </div>
      <BorderBoxContent>
        <ListTable
          posts={data as Post20Days[]}
          handleSetState={handleSetState}
          isAll={true}
        ></ListTable>
      </BorderBoxContent>
    </>
  );
};

export default GetList;
