/* import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { ComponentContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Tabs from 'components/commons/Tabs';
import DropdownMenu from 'components/report/DropdownMenu';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { useDateRange } from 'libraries/hooks/date';
import {
  ListPayReceiptType,
  PayOrderReport,
  PayOtherReport,
  ReceiptOrderReport,
  ReceiptOtherReport,
} from 'models/report.model';
import { ReactElement, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetFinanceReportQuery } from 'services/report.service';
import styled from 'styled-components';
import useQuery from '../../../libraries/hooks/query';
import ExportExcel from './ExportExcel';
import FinanceSummary from './FinanceSummary';
import OrderPayment from './order-payment/OrderPayment';
import OrderReceiptTable from './order-receipt/OrderReceiptTable';
import OtherPayment from './other-payment/OtherPayment';
import OtherReceiptTable from './other-receipt/OtherReceiptTable';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const { TabPane } = Tabs;

const FinanceReportContent = (): ReactElement => {
  const searchParams = useQuery();
  const activeKey = searchParams.get('tab') ?? '1';
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange();
  const { data, isError, error } = useGetFinanceReportQuery({
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const onChange = (activeKey: string) => {
    searchParams.set('tab', activeKey);
    history.replace({ search: searchParams.toString() });
  };

  const reports =
    data ??
    ({
      receipt_from_other: [] as ReceiptOtherReport[],
      receipt_from_order: [] as ReceiptOrderReport[],
      pay_for_other: [] as PayOtherReport[],
      pay_for_order: [] as PayOrderReport[],
    } as ListPayReceiptType);

  const receipt_from_other = data
    ? data.receipt_from_other
    : ([] as ReceiptOtherReport[]);

  const receipt_from_order = data
    ? data.receipt_from_order
    : ([] as ReceiptOrderReport[]);
  const pay_for_other = data ? data.pay_for_other : ([] as PayOtherReport[]);
  const pay_for_order = data ? data.pay_for_order : ([] as PayOrderReport[]);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Báo cáo thu chi" />
      <GroupButton>
        <LinksContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginTop: '5px' }}>
            <Dropdown
              overlay={
                <DropdownMenu
                  setVisible={setVisible}
                  firstDay={firstDay}
                  lastDay={lastDay}
                  setFirstDay={setFirstDay}
                  setLastDay={setLastDay}
                />
              }
              placement="bottomLeft"
              arrow
              trigger={['click']}
              visible={visible}
              onVisibleChange={(flag: boolean) => setVisible(flag)}
            >
              <Input
                style={{ width: 250 }}
                addonBefore={<CalendarOutlined />}
                value={`${format(firstDay)} - ${format(lastDay)}`}
              />
            </Dropdown>
          </div>
          <ExportExcel
            reports={reports}
            firstDay={format(firstDay)}
            lastDay={format(lastDay)}
          />
        </LinksContainer>
        <LinksContainer>
          <Link.Success to={generatePath(route.report.pay.create)}>
            <PlusOutlined /> Phiếu chi
          </Link.Success>
          <Link.Primary to={generatePath(route.report.receipt.create)}>
            <PlusOutlined /> Phiếu thu
          </Link.Primary>
        </LinksContainer>
      </GroupButton>
      <ComponentContent>
        <FinanceSummary reports={reports} />
      </ComponentContent>
      <ComponentContent>
        <Tabs activeKey={activeKey} onChange={onChange}>
          <TabPane tab="Lịch sử thu qua đơn hàng" key="1">
            <OrderReceiptTable reports={receipt_from_order} />
          </TabPane>
          <TabPane tab="Lịch sử thu" key="2">
            <OtherReceiptTable reports={receipt_from_other} />
          </TabPane>
          <TabPane tab="Lịch sử chi" key="3">
            <OtherPayment reports={pay_for_other} />
          </TabPane>
          <TabPane tab="Hoàn trả học phí" key="4">
            <OrderPayment reports={pay_for_order} />
          </TabPane>
        </Tabs>
      </ComponentContent>
    </>
  );
};

export default FinanceReportContent;
 */

import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import Breadcrumb from 'components/commons/Breadcrumb';
import { ComponentContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Tabs from 'components/commons/Tabs';
import DropdownMenu from 'components/report/DropdownMenu';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { useDateRange } from 'libraries/hooks/date';
import {
  ListPayReceiptType,
  PayOrderReport,
  PayOtherReport,
  ReceiptOrderReport,
  ReceiptOtherReport,
} from 'models/report.model';
import { ReactElement, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetFinanceReportQuery } from 'services/report.service';
import styled from 'styled-components';
import useQuery from '../../../libraries/hooks/query';
import ExportExcel from './ExportExcel';
import FinanceSummary from './FinanceSummary';
import OrderPayment from './order-payment/OrderPayment';
import OrderReceiptTable from './order-receipt/OrderReceiptTable';
import OtherPayment from './other-payment/OtherPayment';
import OtherReceiptTable from './other-receipt/OtherReceiptTable';

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const { TabPane } = Tabs;

const FinanceReportContent = (): ReactElement => {
  const searchParams = useQuery();
  const activeKey = searchParams.get('tab') ?? '1';
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange();
  const { data, isError, error, refetch } = useGetFinanceReportQuery({
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });

  const onChange = (activeKey: string) => {
    searchParams.set('tab', activeKey);
    history.replace({ search: searchParams.toString() });
  };

  const reports =
    data ??
    ({
      receipt_from_other: [] as ReceiptOtherReport[],
      receipt_from_order: [] as ReceiptOrderReport[],
      pay_for_other: [] as PayOtherReport[],
      pay_for_order: [] as PayOrderReport[],
    } as ListPayReceiptType);

  const receipt_from_other = data
    ? data.receipt_from_other
    : ([] as ReceiptOtherReport[]);

  const receipt_from_order = data
    ? data.receipt_from_order
    : ([] as ReceiptOrderReport[]);
  const pay_for_other = data ? data.pay_for_other : ([] as PayOtherReport[]);
  const pay_for_order = data ? data.pay_for_order : ([] as PayOrderReport[]);

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb text="Báo cáo thu chi" />
      <GroupButton>
        <LinksContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginTop: '5px' }}>
            <Dropdown
              overlay={
                <DropdownMenu
                  setVisible={setVisible}
                  firstDay={firstDay}
                  lastDay={lastDay}
                  setFirstDay={setFirstDay}
                  setLastDay={setLastDay}
                />
              }
              placement="bottomLeft"
              arrow
              trigger={['click']}
              visible={visible}
              onVisibleChange={(flag: boolean) => setVisible(flag)}
            >
              <Input
                style={{ width: 250 }}
                addonBefore={<CalendarOutlined />}
                value={`${format(firstDay)} - ${format(lastDay)}`}
              />
            </Dropdown>
          </div>
          <ExportExcel
            firstDay={firstDay.toISOString()}
            lastDay={lastDay.toISOString()}
          />
        </LinksContainer>
        <LinksContainer>
          <Link.Success to={generatePath(route.report.pay.create)}>
            <PlusOutlined /> Phiếu chi
          </Link.Success>
          <Link.Primary to={generatePath(route.report.receipt.create)}>
            <PlusOutlined /> Phiếu thu
          </Link.Primary>
        </LinksContainer>
      </GroupButton>
      <ComponentContent>
        <FinanceSummary reports={reports} />
      </ComponentContent>
      <ComponentContent>
        <Tabs activeKey={activeKey} onChange={onChange}>
          <TabPane tab="Lịch sử thu qua đơn hàng" key="1">
            <OrderReceiptTable reports={receipt_from_order} />
          </TabPane>
          <TabPane tab="Lịch sử thu" key="2">
            <OtherReceiptTable reports={receipt_from_other} />
          </TabPane>
          <TabPane tab="Lịch sử chi" key="3">
            <OtherPayment reports={pay_for_other} />
          </TabPane>
          <TabPane tab="Hoàn trả học phí" key="4">
            <OrderPayment reports={pay_for_order} refetch={refetch} />
          </TabPane>
        </Tabs>
      </ComponentContent>
    </>
  );
};

export default FinanceReportContent;
