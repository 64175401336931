import { Col, Row } from 'antd';
import { ListPayReceiptType } from 'models/report.model';
import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatMoneyVnd } from 'libraries/utils/changeFormatData';

interface PropsType {
  reports: ListPayReceiptType;
}

const SummaryRow = styled(Row)`
  .ant-col {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    .description-block {
      margin: 10px 0;
    }
    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        .description-block {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
`;

const DescriptionText = styled.span`
  display: block;
  text-transform: uppercase;
`;
const Price = styled.span`
  font-size: 22px;
  font-weight: 600;
  &.success {
    color: #00a65a;
  }
  &.danger {
    color: #dd4b39;
  }
  &.warning {
    color: #f39c12;
  }
  &.info {
    color: #0073b7;
  }
`;

const FinanceSummary = ({ reports }: PropsType): ReactElement => {
  const [totalReceipt, setTotalReceipt] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalOrderPayment, setOrderPayment] = useState(0);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const total_receipt_other = reports.receipt_from_other.reduce(
      (total, item) => {
        total += item.amount;
        return total;
      },
      0,
    );
    const total_receipt_order = reports.receipt_from_order.reduce(
      (total, item) => {
        total += item.amount;
        return total;
      },
      0,
    );
    setTotalReceipt(total_receipt_order + total_receipt_other);

    const total_payment = reports.pay_for_other.reduce((count, item) => {
      count += item.amount;
      return count;
    }, 0);
    setTotalPayment(total_payment);

    /* const total_order_payment = reports.pay_for_order.reduce((count, item) => {
      count += item.amount;
      return count;
    }, 0); */
    const total_order_payment = reports.pay_for_order.reduce((count, item) => {
      if (item.status === 'approve') {
        count += item.amount;
      }
      return count;
    }, 0);
    setOrderPayment(total_order_payment);
    setTotal(
      total_receipt_order +
        total_receipt_other -
        total_payment -
        total_order_payment,
    );
  }, [reports]);

  return (
    <SummaryRow gutter={[30, 30]}>
      <Col xs={24} md={6}>
        <div className="description-block">
          <DescriptionText>Thu</DescriptionText>
          <Price className="success">{formatMoneyVnd(totalReceipt)}</Price>
        </div>
      </Col>
      <Col xs={24} md={6}>
        <div className="description-block">
          <DescriptionText>Chi</DescriptionText>
          <Price className="danger">{formatMoneyVnd(totalPayment)}</Price>
        </div>
      </Col>
      <Col xs={24} md={6}>
        <div className="description-block">
          <DescriptionText>Hoàn học phí</DescriptionText>
          <Price className="warning">{formatMoneyVnd(totalOrderPayment)}</Price>
        </div>
      </Col>
      <Col xs={24} md={6}>
        <div className="description-block">
          <DescriptionText>Tổng doanh thu</DescriptionText>
          <Price className="info">{formatMoneyVnd(total)}</Price>
        </div>
      </Col>
    </SummaryRow>
  );
};

export default FinanceSummary;
