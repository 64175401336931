import { ROLE_SALE } from 'libraries/utils/globalVariable';
import { ReactElement, useState } from 'react';
import { useGetUsersByRoleQuery } from 'services/user.service';

import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderTopBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import { UserWithRole } from 'models/user.model';
import SaleTable from './SaleTable';
import { useDateRange } from 'libraries/hooks/date';
import { CalendarOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import DropdownMenu from 'components/report/DropdownMenu';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import styled from 'styled-components';
import KpiSaleCart from './KpiSaleCart';
import {
  useGetApiSaleListQuery,
  useGetFinanceReportQuery,
} from 'services/report.service';
const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
function totalRevenue(data: any) {
  return data.reduce(
    (total: number, item: any) => total + item.other_finance,
    0,
  );
}

const GetKpiSaleList = (): ReactElement => {
  const [visible, setVisible] = useState<any>(false);
  // const { data, isError, error } = useGetUsersByRoleQuery(ROLE_SALE);

  // const salers = data ? data : ([] as UserWithRole[]);
  const { firstDay, lastDay, setFirstDay, setLastDay, format } = useDateRange();
  const { data, isError, error, isLoading } = useGetApiSaleListQuery({
    from: firstDay.toISOString(),
    to: lastDay.toISOString(),
  });
  if (isError) {
    return <ShowServerError error={error} />;
  }
  return (
    <>
      <Breadcrumb text="KPI Nhân viên tuyển sinh" />
      <GroupButton>
        <LinksContainer style={{ margin: '10px 0' }}>
          <Dropdown
            overlay={
              <DropdownMenu
                setVisible={setVisible}
                firstDay={firstDay}
                lastDay={lastDay}
                setFirstDay={setFirstDay}
                setLastDay={setLastDay}
              />
            }
            placement="bottomLeft"
            arrow
            visible={visible}
            trigger={['click']}
            onVisibleChange={(flag: boolean) => setVisible(flag)}
          >
            <Input
              style={{ width: 250 }}
              addonBefore={<CalendarOutlined />}
              value={`${format(firstDay)} - ${format(lastDay)}`}
            />
          </Dropdown>
        </LinksContainer>
      </GroupButton>
      <BorderTopBoxContent>
        <span style={{ fontSize: 20, fontWeight: 600 }}>
          Tổng doanh thu FE, PHP, Flutter, Web:
        </span>
        {data && (
          <span
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: '#0e186f',
              marginLeft: 12,
            }}
          >
            {totalRevenue(data).toLocaleString('vi')} ₫
          </span>
        )}
        {data && <KpiSaleCart data={data} />}
      </BorderTopBoxContent>
    </>
  );
};

export default GetKpiSaleList;
