import { LeftOutlined } from '@ant-design/icons';
import { Avatar, Checkbox, message, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Key } from 'antd/lib/table/interface';
import Title from 'antd/lib/typography/Title';
import DefaultAvatar from 'assets/images/avatar.png';
import TableContainer from 'components/table/TableContainer';
import Button from 'libraries/components/commons/Button';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ClassAttendance, LessonAttendance } from 'models/class.model';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import ReactExport from 'react-data-export';
import {
  useGetClassAttendancesQuery,
  useUpdateLessonAttendanceMutation,
} from 'services/classes.service';
import {
  checkAll,
  checkAttendance,
  checkAttendanceOnline,
  checkOnlineAll,
  selectClass,
  selectLessonAttendances,
  uncheckAll,
} from 'store/classes.slice';
import styled from 'styled-components';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const TableStyle = styled.span`
  .ant-table-thead > tr th:nth-child(2),
  .ant-table-thead > tr th:nth-child(3) {
    span {
      padding-right: 0;
    }
    width: 82px;
  }
`;
const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getTimeAttendCurrent = (lessonId: any, infoclass: ClassAttendance[]) => {
  const result = infoclass.filter((data) => {
    return lessonId == data.lesson_id;
  });
  return result[0]?.complete_date;
};
const getValueTimeAttend = (time: any): string => {
  if (time < 14) {
    return 'Sáng ';
  } else if (time < 18) {
    return 'Chiều ';
  } else {
    return 'Tối ';
  }
};
const AttendanceTable = (): ReactElement => {
  const { classId, lessonId } = useParams<{
    classId: string;
    lessonId: string;
  }>();

  const attendances = useSelector(selectLessonAttendances);
  const classroom = useSelector(selectClass);
  const { type: typeClass } = classroom;
  const dispatch = useDispatch();
  const [updateLessonAttendance] = useUpdateLessonAttendanceMutation();
  const { id } = useSelector(selectClass);
  const { data } = useGetClassAttendancesQuery(id);
  const infoclass = data ? data : ([] as ClassAttendance[]);

  const dateMoment = moment(getTimeAttendCurrent(lessonId, infoclass));
  const date = dateMoment.format('DD-MM-YYYY');

  // const listIdHasAttend: Key[] = [];
  const listIdHasAttendOffline: Key[] = [];
  const listIdHasAttendOnline: Key[] = [];
  const listStudentsHasAttendOffline = attendances.filter(
    (student) => student.is_attend,
  );
  const listStudentsHasAttendOnline = attendances.filter(
    (student) => student.attend_online,
  );
  const listStudentsHasAttend = attendances.filter(
    (student) => student.is_attend || student.attend_online,
  );

  const listStudentsNotAttend = attendances.filter(
    (student) => !student.is_attend && !student.attend_online,
  );

  const lesson = infoclass.map((item, i) => {
    if (item.lesson_id === lessonId) {
      return (
        <span key={i} style={{ color: '#0275d8' }}>
          {item.complete_date !== ''
            ? formatDate(item.complete_date)
            : item.lesson_id}
        </span>
      );
    }
  });
  const [studentsHasAttend, setStudentsHasAttend] = useState<string>('');
  const numbers = infoclass.map((item) => {
    if (item.lesson_id === lessonId) {
      return (
        <>
          {studentsHasAttend !== '' ? (
            <>
              <span style={{ color: '#0275d8' }}>
                {studentsHasAttend.split('/')[0]}
              </span>
              /{studentsHasAttend.split('/')[1]}
            </>
          ) : (
            <>
              <span style={{ color: '#0275d8' }}>{item.attendances}</span>/
              {item.total}
            </>
          )}
        </>
      );
    }
  });

  listStudentsHasAttendOffline.forEach((student) =>
    listIdHasAttendOffline.push(student.student_id),
  );
  listStudentsHasAttendOnline.forEach((student) =>
    listIdHasAttendOnline.push(student.student_id),
  );
  const handleCheckIsAttendAll = (e: any) => {
    if (e.target.checked == true) {
      dispatch(checkAll());
    } else {
      dispatch(uncheckAll());
    }
  };
  const handleCheckAttendOnlineAll = (e: any) => {
    if (e.target.checked == true) {
      dispatch(checkOnlineAll());
    } else {
      dispatch(uncheckAll());
    }
  };

  //   const [selectedRowKeys, setSelectedRowKeys] =
  //     useState<Key[]>(listIdHasAttend);

  const columns: ColumnsType<LessonAttendance> = [
    {
      key: 'stt',

      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: '3%',
      render: (_, { type }, index) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: <p style={{ margin: 0 }}>{index + 1}</p>,
        };
      },
    },
    {
      key: 'is_attend',
      title:
        typeClass !== 'live' ? (
          <Checkbox
            indeterminate={
              listIdHasAttendOffline.length > 0 &&
              listIdHasAttendOffline.length < attendances.length
                ? true
                : false
            }
            checked={
              listIdHasAttendOffline.length === attendances.length
                ? true
                : false
            }
            onChange={handleCheckIsAttendAll}
          >
            Onlab
          </Checkbox>
        ) : (
          <Checkbox defaultChecked={false} disabled>
            Onlab
          </Checkbox>
        ),
      dataIndex: 'is_attend',
      render: (is_attend, { student_id, type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children:
            typeClass !== 'live' ? (
              <Checkbox
                onChange={() => dispatch(checkAttendance(student_id))}
                checked={is_attend}
              ></Checkbox>
            ) : (
              <Checkbox defaultChecked={false} disabled />
            ),
        };
      },
    },
    {
      key: 'attend_online',
      title: (
        <Checkbox
          indeterminate={
            listIdHasAttendOnline.length > 0 &&
            listIdHasAttendOnline.length < attendances.length
              ? true
              : false
          }
          checked={
            listIdHasAttendOnline.length === attendances.length ? true : false
          }
          onChange={handleCheckAttendOnlineAll}
        >
          Online
        </Checkbox>
      ),
      dataIndex: 'attend_online',
      render: (attend_online, student) => {
        const { student_id, type } = student;
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: (
            <Checkbox
              onChange={() => dispatch(checkAttendanceOnline(student_id))}
              checked={attend_online}
            ></Checkbox>
          ),
        };
      },
    },
    {
      key: 'avatar',
      title: 'Avatar',
      align: 'center',
      dataIndex: 'avatar',
      render: (avatar, { type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: (
            <Avatar
              size={60}
              src={avatar !== '' ? avatar : DefaultAvatar}
              alt="avatar"
            />
          ),
        };
      },
    },

    {
      key: 'full_name',
      title: 'Học viên',
      dataIndex: 'full_name',
      render: (_text, { type, student_id, full_name }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: (
            <RouterLink
              to={generatePath(route.class.student, {
                classId: classId,
                studentId: student_id,
                orderId: 0,
              })}
              // orderId:0 : mặc định. Truyền đúng orderId của từng người để xem ngày sinh
              target="_blank"
            >
              {full_name}
            </RouterLink>
          ),
        };
      },
    },
    {
      key: 'status',
      title: 'Trạng thái',
      dataIndex: 'is_attend',
      render: (is_attend: boolean, { attend_online, type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children:
            !is_attend && !attend_online ? (
              <span style={{ color: '#FD0404', lineHeight: '30px' }}>
                Vắng mặt
              </span>
            ) : (
              <>
                {((attend_online && type === 'live') || is_attend) && (
                  <span style={{ color: '#439241', marginRight: 30 }}>
                    Đi học
                  </span>
                )}
                {attend_online && type !== 'live' && (
                  <span style={{ color: 'rgb(253, 4, 4)', marginRight: 30 }}>
                    Đi học
                  </span>
                )}
              </>
            ),
        };
      },
    },
    {
      key: 'review',
      title: 'Đánh giá',
      dataIndex: 'review',
      render: (_: void, { vote_content, vote_teacher, type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: (
            <span>
              Giảng viên: <strong>{vote_teacher}</strong> / 5 <br /> Buổi học:{' '}
              <strong>{vote_content}</strong> / 5
            </span>
          ),
        };
      },
    },
    {
      key: 'note',
      title: 'Nhận xét',
      dataIndex: 'student_note',
      render: (student_note, { type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: (
            <Tooltip placement="bottomLeft" title={student_note}>
              {student_note}
            </Tooltip>
          ),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: (email, { type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: <a href={`mailto:${email}`}>{email}</a>,
        };
      },
    },

    {
      key: 'phone',
      title: 'SDT',
      dataIndex: 'phone',
      render: (phone, { type }) => {
        return {
          props: {
            style: { background: type === 'live' ? '#e6f7ff' : '' },
          },
          children: <a href={`tel:${phone}`}>{phone}</a>,
        };
      },
    },
  ];

  const attendance = () => {
    updateLessonAttendance({
      class_id: classId,
      lesson_id: lessonId,
      data: [...listStudentsHasAttend, ...listStudentsNotAttend],
    })
      .unwrap()
      .then(
        (data) => setStudentsHasAttend(data.class_size),
        message.success('Điểm danh thành công'),
      )

      .catch((error) => {
        message.error(error.data);
      });
  };

  // const handleChange = (value: string, student_id: string) => {
  //   let attend_online: boolean;
  //   if (value === 'true') {
  //     attend_online = true;
  //   } else {
  //     attend_online = false;
  //   }
  //   dispatch(changeAttendOnline({ student_id, attend_online }));
  // };

  // const onSelectChange = (selected: Key[]) => {
  //   setSelectedRowKeys(selected);
  // };

  const handleStatusAttend = (selectedRows: LessonAttendance) => {
    const { student_id } = selectedRows;
    dispatch(checkAttendance(student_id));
  };

  const handleNone = (selectedRows: boolean) => {
    selectedRows ? dispatch(checkAll()) : dispatch(uncheckAll());
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  //   onSelect: handleStatusAttend,
  //   onSelectAll: handleNone,
  // };

  return (
    <>
      <GroupButton>
        <LinksContainer>
          <Link.Default to={generatePath(route.class.detail, { classId })}>
            <LeftOutlined /> Quay lại
          </Link.Default>

          <Button.History onClick={attendance}>Điểm danh</Button.History>
          <ExcelFile
            element={<Link.Primary to="#">Xuất Excel</Link.Primary>}
            filename={`Điểm danh ngày ${date}`}
          >
            {attendances.length != 0 ? (
              <ExcelSheet
                data={attendances}
                name={`Danh sách học viên điểm danh ngày ${date}`}
              >
                <ExcelColumn label="Học viên" value="full_name" />
                <ExcelColumn
                  label="Điểm danh"
                  value={(col: LessonAttendance) => {
                    if (col.attend_online == false && col.is_attend == false) {
                      return 'Vắng mặt';
                    } else {
                      return 'Đi học';
                    }
                  }}
                />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="SDT" value="phone" />
              </ExcelSheet>
            ) : (
              false
            )}
          </ExcelFile>
        </LinksContainer>

        <LinksContainer>
          <Title level={5} style={{ color: '#0303033d', margin: '0' }}>
            Buổi học:
            <span style={{ color: 'rgb(2, 117, 216)' }}>
              {getValueTimeAttend(dateMoment.hour())}
            </span>
            {lesson}
          </Title>
          <Title level={5} style={{ color: '#0303033d', margin: '0' }}>
            Sĩ số: {numbers}
          </Title>
        </LinksContainer>
      </GroupButton>
      <div
        style={{
          display: 'flex',
          marginBottom: '20px',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            marginRight: 10,
            display: 'inline-block',
            width: 20,
            height: 20,
            backgroundColor: '#e6f7ff',
            border: '1px solid #ccc',
          }}
        ></div>
        <span style={{ fontWeight: '500' }}>Đăng kí học online</span>
      </div>
      <TableContainer>
        <TableStyle>
          <Table<LessonAttendance>
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={attendances}
            pagination={false}
            rowKey="student_id"
            bordered
          />
        </TableStyle>
      </TableContainer>
    </>
  );
};

export default AttendanceTable;
