import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { classTypeValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ClassAttendance } from 'models/class.model';
import { ReactElement } from 'react';
import ReactExport from 'react-data-export';
import { useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetClassAttendancesQuery } from 'services/classes.service';
import { selectClass } from 'store/classes.slice';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ClassAttendances = (): ReactElement => {
  const { id, name, total_learns } = useSelector(selectClass);
  const { data } = useGetClassAttendancesQuery(id);
  const attendances = data ? data : ([] as ClassAttendance[]);

  const columns: ColumnsType<ClassAttendance> = [
    {
      key: 'lesson_id',
      title: 'Buổi học',
      dataIndex: 'lesson_id',
      render: (_text, item) => {
        return (
          <RouterLink
            to={generatePath(route.class.attendance, {
              classId: id,
              lessonId: item.lesson_id,
            })}
          >
            {item.complete_date !== ''
              ? formatDate(item.complete_date)
              : item.lesson_id}
          </RouterLink>
        );
      },
    },
    {
      key: 'type',
      title: 'Hình thức',
      dataIndex: 'type',
      render: (type) => {
        if (type !== '') {
          for (let i = 0; i < classTypeValues.length; i++) {
            const item = classTypeValues[i];
            if (item.value === type) {
              return item.label;
            }
          }
        } else {
          return '';
        }
      },
    },
    {
      key: 'trainer',
      title: 'Người điểm danh',
      dataIndex: 'trainer',
    },
    {
      key: 'attendances',
      title: 'Sĩ số',
      dataIndex: 'attendances',
      render: (_text, item) => `${item.attendances}/${item.total}`,
    },
  ];

  return (
    <>
      {attendances.length > 0 && (
        <LinksContainer>
          <ExcelFile
            element={<Link.Primary to="#">Xuất Excel</Link.Primary>}
            filename={`Lịch sử điểm danh lớp ${name}`}
          >
            <ExcelSheet data={attendances} name="Danh sách học viên">
              <ExcelColumn
                label="Buổi học"
                value={(col: ClassAttendance) =>
                  col.complete_date !== '' ? formatDate(col.complete_date) : ''
                }
              />
              <ExcelColumn
                label="Hình thức"
                value={(col: ClassAttendance) => {
                  if (col.type !== '') {
                    for (let i = 0; i < classTypeValues.length; i++) {
                      const item = classTypeValues[i];
                      if (item.value === col.type) {
                        return item.label;
                      }
                    }
                  } else {
                    return '';
                  }
                }}
              />
              <ExcelColumn label="Người điểm danh" value="trainer" />
              <ExcelColumn
                label="Sĩ số"
                value={(col: ClassAttendance) =>
                  `${col.attendances}/${col.total}`
                }
              />
            </ExcelSheet>
          </ExcelFile>
        </LinksContainer>
      )}

      <div style={{ marginBottom: '10px' }}>
        Số buổi đã học &ensp;
        <span style={{ fontSize: '2.5rem', color: '#dd4b39' }}>
          {attendances.length}/{total_learns}
        </span>
      </div>
      <TableContainer>
        <Table<ClassAttendance>
          columns={columns}
          dataSource={attendances}
          pagination={false}
          rowKey="lesson_id"
          bordered
        />
      </TableContainer>
    </>
  );
};
export default ClassAttendances;
