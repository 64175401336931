import Breadcrumb from 'components/commons/Breadcrumb';
import { BorderBoxContent } from 'components/commons/ComponentContent';
import ShowServerError from 'components/commons/ShowServerError';
import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import { advisoryInterestedValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { Advisory } from 'models/order.model';
import { ReactElement, useState } from 'react';
import ReactExport from 'react-data-export';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { useGetListAdvisoryQuery } from 'services/order.service';
import { selectListAdvisories } from 'store/order.slice';
import AdvisoriesList from './AdvisoriesList';

interface KeySearchType {
  fullname: string;
  status: string;
  phone: string;
  email: string;
  interested: string;
  sale_id: string;
  item: string;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GetList = (): ReactElement => {
  const [keyword, setKeyword] = useState(
    '?page=1&fullname=&status=&phone=&email=&interested=&sale_id=&item=',
  );

  const [currentPage, setCurrentPage] = useState(1);
  const { data, isError, error } = useGetListAdvisoryQuery(keyword);

  const listAdvisories = (data && data.list_advisories) || ([] as Advisory[]);

  const total_page = data ? data.total_page : 0;

  const [state, setState] = useState<KeySearchType>({
    fullname: '',
    status: '',
    phone: '',
    email: '',
    interested: '',
    sale_id: '',
    item: '',
  });

  const handleSetState = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  if (isError) {
    return <ShowServerError error={error} />;
  }

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.order.list)}
        secondaryText="Đơn hàng"
        text="Tư vấn"
      />
      <LinksContainer>
        {listAdvisories.length > 0 && (
          <ExcelFile
            element={<Link.Primary to="#">Xuất Excel</Link.Primary>}
            filename={'Danh sách tư vấn'}
          >
            <ExcelSheet data={listAdvisories} name="Danh sách tư vấn">
              <ExcelColumn label="Họ tên" value="full_name" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="SĐT" value="phone" />
              <ExcelColumn label="Khoá học quan tâm" value="item" />
              <ExcelColumn
                label="Ngày gửi"
                value={(col: Advisory) =>
                  col.date !== '' ? formatDate(col.date) : ''
                }
              />
              <ExcelColumn label="Trạng thái" value="status" />
              <ExcelColumn
                label="Mức độ quan tâm"
                value={(col: Advisory) => {
                  for (let i = 0; i < advisoryInterestedValues.length; i++) {
                    const item = advisoryInterestedValues[i];
                    if (item.value === col.interested) {
                      return item.label;
                    }
                  }
                }}
              />
            </ExcelSheet>
          </ExcelFile>
        )}
      </LinksContainer>
      <BorderBoxContent>
        <AdvisoriesList
          advisories={listAdvisories}
          total_page={total_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setKeyword={setKeyword}
          state={state}
          handleSetState={handleSetState}
        />
      </BorderBoxContent>
    </>
  );
};
export default GetList;
