import { EditorView } from 'codemirror';
import { tags as t } from '@lezer/highlight';
import { tags as customTags } from './custom';
import { HighlightStyle } from '@codemirror/language';

const syntaxHue = '230';

export const mono1 = `hsl(${syntaxHue},8%,24%)`,
  mono2 = `hsl(${syntaxHue},6%,44%)`,
  mono3 = `hsl(${syntaxHue},4%,64%)`;

export const hue1 = 'hsl(198, 99%, 37%)',
  hue2 = 'hsl(221, 87%, 60%)',
  hue3 = 'hsl(301, 63%, 40%)',
  hue4 = 'hsl(119, 34%, 47%)',
  hue5 = 'hsl(  5, 74%, 59%)',
  hue5_2 = 'hsl(344, 84%, 43%)',
  hue6 = 'hsl(41, 99%, 30%)',
  hue6_2 = 'hsl(41, 99%, 38%)';

const syntaxFg = `${mono1}`,
  syntaxAccent = `hsl${syntaxHue},100%,66%`;

const ivory = '#222222',
  stone = '#7d8799',
  highlightBackground = hue2;

export const background = '#ffffff',
  font =
    'monospace, Mono, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,Mono, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  fontTxt =
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
  color = '#222222',
  cursor = hue2,
  highlight = '#eeeeee',
  selection = '#eeeeee',
  selectionMatch = '#eeeeee',
  lineNumber = '#999999',
  matchingBracket = '#b7ccfa',
  tooltipBackground = '#e3e3e3';

export const customTheme = EditorView.theme(
  {
    '&': {
      color: color,
      backgroundColor: background,
      height: '100%',
    },

    '.cm-content': {
      caretColor: cursor,
    },

    '&.cm-editor': {
      borderLeft: '1px solid #e3e3e3',
    },

    '&.cm-editor.cm-focused': {
      outline: 'none',
    },

    '& .cm-scroller': {
      fontFamily: fontTxt,
      fontSize: '16px',
    },

    '.cm-cursor, .cm-dropCursor': {
      borderLeftColor: cursor,
      borderLeft: `2px solid ${cursor}`,
    },
    '&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection':
      {
        backgroundColor: selection,
        color: 'unset',
      },

    '.cm-panels': { backgroundColor: background, color: color },
    '.cm-panels.cm-panels-top': { borderBottom: '2px solid black' },
    '.cm-panels.cm-panels-bottom': {
      border: '1px solid #e3e3e3',
      borderLeft: 0,
      borderTop: 0,
      minHeight: '1em',
      padding: '3px',
    },

    '.cm-searchMatch': {
      backgroundColor: '#72a1ff59',
      outline: '1px solid #457dff',
    },
    '.cm-searchMatch.cm-searchMatch-selected': {
      backgroundColor: '#6199ff2f',
    },

    '.cm-activeLine': { backgroundColor: highlight },
    '.cm-selectionMatch': { backgroundColor: selectionMatch },

    '&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket': {
      backgroundColor: matchingBracket,
    },

    '.cm-gutters': {
      backgroundColor: background,
      color: lineNumber,
      border: 'none',
    },

    '.cm-activeLineGutter': {
      backgroundColor: highlight,
    },

    '.cm-foldPlaceholder': {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#ddd',
    },

    '.cm-tooltip': {
      border: 'none',
      padding: '3px 0',
      backgroundColor: tooltipBackground,
    },
    '.cm-tooltip .cm-tooltip-arrow:before': {
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '.cm-tooltip .cm-tooltip-arrow:after': {
      borderTopColor: tooltipBackground,
      borderBottomColor: tooltipBackground,
    },
    '.cm-tooltip-autocomplete': {
      '& > ul > li[aria-selected]': {
        backgroundColor: highlightBackground,
        color: ivory,
      },
    },
    '.cm-scroller': {
      overflow: 'scroll',
      overflowX: 'unset',
      overflowY: 'scroll',
      borderBottom: '1px solid #e3e3e3',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      padding: '5px 10px',
    },
  },
  { dark: false },
);

export const customHighlight = HighlightStyle.define([
  {
    tag: [t.keyword, t.operatorKeyword],
    color: hue3,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName],
    color: syntaxFg,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.deleted, t.character, t.propertyName, t.macroName],
    fontFamily: font,
    color: hue3,
  },
  {
    tag: [t.labelName, t.propertyName, t.namespace, t.tagName],
    color: hue5,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.color, t.constant(t.variableName), t.standard(t.name)],
    color: hue6,
    fontFamily: font,
  },
  {
    tag: [t.definitionOperator],
    color: hue1,
    fontFamily: font,
  },
  {
    tag: [t.definition(t.variableName), t.className],
    color: hue6_2,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.separator],
    color: syntaxFg,
  },
  {
    tag: [
      t.typeName,
      t.className,
      t.number,
      t.changed,
      t.modifier,
      t.function(t.variableName),
    ],
    color: hue1,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.className],
    color: hue6_2,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.number],
    color: hue6,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.self, t.namespace],
    color: hue5_2,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: [t.url, t.escape, t.regexp, t.link, t.special(t.string), t.string],
    color: hue4,
    fontFamily: font,
  },
  {
    tag: [t.operator],
    color: hue2,
    fontFamily: font,
  },
  {
    tag: [t.meta, t.comment],
    color: stone,
  },
  {
    tag: t.strong,
    fontWeight: '600',
  },
  {
    tag: t.emphasis,
    fontStyle: 'italic',
  },

  {
    tag: t.link,
    color: stone,
    textDecoration: 'none',
    fontFamily: font,
  },
  { tag: t.heading, fontWeight: 'bold', color: color, fontFamily: 'Helvetica' },
  {
    tag: t.heading1,
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '40px',
    fontFamily: `Helvetica, ${fontTxt}`,
  },
  {
    tag: t.heading2,
    fontSize: '1.5em',
    fontWeight: '600',
    lineHeight: '1.25',
    fontFamily: `Helvetica, ${fontTxt}`,
  },
  {
    tag: t.heading3,
    fontSize: '1.25em',
    fontWeight: '600',
    lineHeight: '1.25',
    fontFamily: `Helvetica, ${fontTxt}`,
  },
  {
    tag: t.heading4,
    fontSize: '1em',
    fontWeight: '600',
    lineHeight: '1.25',
    fontFamily: `Helvetica, ${fontTxt}`,
  },
  {
    tag: t.heading5,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '1.25',
    fontFamily: `Helvetica, ${fontTxt}`,
  },
  {
    tag: customTags.inlineCode,
    padding: '0 4px',
    margin: 0,
    backgroundColor: '#f9e0df',
    borderRadius: '6px',
    color: '#8F0E00',
    fontFamily: 'monospace,Mono, Liberation Mono',
  },
  {
    tag: [t.monospace],
    padding: '0.2em,0.4em',
    margin: 0,
    borderRadius: '6px',
    fontSize: '16px',
    color: '#24292f',
    fontFamily: 'monospace,Mono, Liberation Mono',
  },
  {
    tag: [t.atom, t.bool, t.special(t.variableName), t.null, t.number],
    color: hue6,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: t.atom,
    color: hue1,
  },
  {
    tag: [t.processingInstruction, t.inserted],
    color: color,
    fontFamily: fontTxt,
  },
  {
    tag: [t.string],
    color: hue4,
    fontFamily: font,
    fontSize: '16px',
  },
  {
    tag: t.invalid,
    color: hue5_2,
  },
  {
    tag: [t.attributeName],
    color: hue2,
  },
  {
    tag: [t.punctuation],
    color: hue1,
  },
  {
    tag: [t.bracket],
    color: syntaxFg,
  },
  {
    tag: [t.function(t.propertyName)],
    color: hue2,
    fontFamily: font,
  },
  {
    tag: [t.quote],
    color: 'stone',
  },
  {
    tag: [t.squareBracket, t.paren, t.brace],
    color: syntaxAccent,
  },
  {
    tag: [t.changed],
    fontStyle: 'italic',
  },
  {
    tag: [customTags.headingMark],
    color: color,
    fontFamily: 'Helvetica',
    fontWeight: 600,
  },
  {
    tag: [customTags.codeMark],
    fontFamily: font,
    color: color,
    fontWeight: 'inherit',
  },
  {
    tag: [t.link],
    fontFamily: fontTxt,
    color: mono1,
  },
  {
    tag: [customTags.linkMark],
    fontFamily: fontTxt,
    color: color,
    fontWeight: 'inherit',
  },
  { tag: [t.url], color: '#0969da' },
  {
    tag: customTags.highlight,
    color: mono1,
    backgroundColor: '#fff300',
    borderRadius: '5px',
    fontWeight: 600,
    padding: '0 4px',
  },
  {
    tag: customTags.superscript,
    color: color,
    fontWeight: 'inherit',
    verticalAlign: 'super',
    fontSize: '12px',
  },
  {
    tag: customTags.subscript,
    color: color,
    fontWeight: 'inherit',
    verticalAlign: 'sub',
    fontSize: '12px',
  },
  {
    tag: customTags.underline,
    color: color,
    fontWeight: 'inherit',
    textDecoration: 'underline',
    fontSize: 'inherit',
  },
  {
    tag: t.deleted,
    textDecoration: 'line-through',
  },
]);
