import { FC, useRef } from 'react';
import { Timeline as TimelineAntd, Skeleton } from 'antd';
import styled from 'styled-components';
import { formatDate } from 'utils/formatDate';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useGetFixedSalaryHistoryQuery } from 'services/configure.service';
import Breadcrumb from 'components/commons/Breadcrumb';
import { formatNumber } from 'libraries/utils/changeFormatData';
import { ComponentContent as ComponentContentOri } from '../../../components/commons/ComponentContent';

const Timeline = styled(TimelineAntd)`
  width: 100%;
  height: 100%;
`;
const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 180px; */
  width: 100%;
`;
const ComponentContent = styled(ComponentContentOri)`
  min-height: calc(
    100vh - 68px - 40px
  ); // 68 là chiều cao của breadcrumb tính cả padding;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  /* overflow-y: auto; */
  /* padding-top: 140px; */
`;

const HistoryFixedSalary: FC = () => {
  const location = useLocation();
  const { user_id, user_name } = queryString.parse(location.search);
  const { histories } = useGetFixedSalaryHistoryQuery(undefined, {
    selectFromResult: ({ data }) => ({
      histories: data?.find((item) => item.user_id === user_id)?.history,
    }),
  });
  return (
    <>
      <Breadcrumb
        secondaryLink="/configure/salary"
        secondaryText="Cấu hình lương"
        thirdLink="/configure/salary/?tabs=2"
        thirdText="Cấu hình lương cứng"
        text={user_name}
      />
      <ComponentContent>
        <TimelineContainer>
          <>
            {histories ? (
              <Timeline mode="left">
                {histories?.map((history, index, arr) => (
                  <Timeline.Item
                    key={history.start_date}
                    label={formatDate(history.start_date)}
                  >
                    <div style={{ fontWeight: 600 }}>
                      {formatNumber(history.fix_salary)} VNĐ
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Skeleton />
            )}
          </>
        </TimelineContainer>
      </ComponentContent>
    </>
  );
};

export default HistoryFixedSalary;
