import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { ReactElement } from 'react';
import Link from 'libraries/components/commons/Link';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
interface ExcelData {
  id: number;
  point: number;
  status_mark: boolean;
  status_submit: boolean;
  title: string;
  index: number;
}
const ListExcercise = (exercise: any): ReactElement => {
  const { exercise: dataSource } = exercise;
  const dataExcel: ExcelData[] = dataSource?.map(
    (item: any, index: number) => ({
      ...item,
      index: index + 1,
    }),
  );

  const columns: ColumnsType<any> = [
    {
      key: 'index',
      width: '3%',
      title: 'STT',
      render: (_, __, index) => index + 1,
    },
    {
      key: 'title',
      width: '13%',
      title: 'Tên bài tập',
      dataIndex: 'title',
      render: (title, item) => {
        return <div>{title}</div>;
      },
    },
    {
      key: 'status_submit',
      width: '6%',
      title: 'Trạng thái nộp',
      //
      dataIndex: 'status_submit',
      render: (status_submit) => {
        return (
          <div>
            {status_submit ? (
              <div style={{ color: '#0013C0' }}>Đã nộp</div>
            ) : (
              <div style={{ color: '#F60303' }}>Chưa nộp</div>
            )}
          </div>
        );
      },
    },

    {
      key: 'status_mark',
      width: '6%',
      title: 'Trạng thái chấm',
      dataIndex: 'status_mark',
      render: (status_mark) => {
        return (
          <div>
            {status_mark ? (
              <div style={{ color: '#0013C0' }}>Đã chấm</div>
            ) : (
              <div style={{ color: '#F60303' }}>Chưa chấm</div>
            )}
          </div>
        );
      },
    },

    {
      key: 'point',
      width: '6%',
      title: 'Điểm',
      dataIndex: 'point',
      render: (point) => {
        return point;
      },
    },
  ];
  const columns1: ColumnsType<any> = [
    {
      key: 'list',
      width: '13%',
      title: 'Danh sách bài tập',
      render: (title, item) => {
        return (
          <div style={{ display: 'flex', gap: '48px' }}>
            <div>
              <p>Tên bài tập: </p>
              <p>Tình trạng nộp: </p>
              <p>Tình trạng chấm: </p>
              <p>Điểm:</p>
            </div>
            <div>
              <p>{item.title}</p>
              <p>
                {item.status_submit ? (
                  <div style={{ color: '#0013C0' }}>Đã nộp</div>
                ) : (
                  <div style={{ color: '#F60303' }}>Chưa nộp</div>
                )}
              </p>
              <p>
                {item.status_mark ? (
                  <div style={{ color: '#0013C0' }}>Đã chấm</div>
                ) : (
                  <div style={{ color: '#F60303' }}>Chưa chấm</div>
                )}
              </p>
              <p>{item.point}</p>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <ExcelFile
          element={<Link.Primary to="#">Xuất Excel</Link.Primary>}
          filename={`Danh sách bài tập `}
        >
          <ExcelSheet data={dataExcel} name="Danh sách bài tập">
            <ExcelColumn label="STT" value="index" />
            <ExcelColumn label="Tên bài tập" value="title" />
            <ExcelColumn
              label="Trạng thái nộp"
              value={(dataExcel: any) =>
                dataExcel.status_mark === true ? 'Đã nộp' : 'Chưa nộp'
              }
            />
            <ExcelColumn
              label="Trạng thái chấm"
              value={(dataExcel: any) =>
                dataExcel.status_submit === true ? 'Đã chấm' : 'Chưa chấm'
              }
            />
            <ExcelColumn label="Điểm" value="point" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      {window.screen.width >= 768 ? (
        <Table columns={columns} dataSource={dataSource} bordered />
      ) : (
        <Table columns={columns1} dataSource={dataSource} bordered />
      )}
    </>
  );
};
export default ListExcercise;
