import { ReactElement, useEffect, useState } from 'react';

import { generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { Col, Input, InputNumber, message, Row, Select, Avatar } from 'antd';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';

import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Breadcrumb from 'components/commons/Breadcrumb';

import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Button from 'libraries/components/commons/Button';
import { trackStatusValues } from 'libraries/utils/globalVariable';
import {
  useGetClassesListQuery,
  useCreateNewTrackMutation,
  useGetTrackMasterQuery,
} from 'services/track.service';
import DefaultAvatar from 'assets/images/avatar.png';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';

const { Option } = Select;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CreateTrackContent = (): ReactElement => {
  const [form] = Form.useForm();

  const { data: classes } = useGetClassesListQuery();
  const { data: trackMasterList } = useGetTrackMasterQuery();

  const [createNewTrack] = useCreateNewTrackMutation();

  const [avatar, setAvatar] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
  };
  // eslint-disable-next-line
  const onFinish = (values: any) => {
    createNewTrack(values)
      .unwrap()
      .then(() => {
        setIsChange(false);
        message.success('Tạo lộ trình thành công!');
      })
      .catch((error) => {
        message.error(error.data);
      });
  };
  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    onFinish(form.getFieldsValue());
  };

  useEffect(() => {
    form.setFieldsValue({
      avatar,
    });
  }, [avatar]);

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.track.list)}
        secondaryText="Lộ trình"
        text="Tạo lộ trình"
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onFieldsChange={() => setIsChange(true)}
        >
          <GroupButton>
            <LinksContainer>
              <Link.Default to={generatePath(route.track.list)}>
                <LeftOutlined /> Quay lại
              </Link.Default>
            </LinksContainer>
          </GroupButton>

          <Row gutter={[30, 30]}>
            <Col xs={24} md={16}>
              <Form.Item
                label={<FormLabel label="Tên" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên lộ trình!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{ borderRadius: '5px' }}
                label={<FormLabel label="Giới thiệu" require={true} />}
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng viết mô tả về lộ trình!',
                  },
                ]}
              >
                <Editor
                  value="description"
                  height={400}
                  onChange={handleChangeDescription}
                />
              </Form.Item>

              <div style={{ marginTop: '8px' }}>
                <Button.Primary
                  style={{ fontSize: '14px' }}
                  htmlType="submit"
                  disabled={!isChange}
                >
                  Tạo lộ trình mới
                </Button.Primary>
              </div>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={true} />}
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn trạng thái!',
                  },
                ]}
              >
                <Select optionFilterProp="children">
                  {trackStatusValues.map((item, index) => (
                    <Option value={item.value.toString()} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Chọn track master" require={true} />}
                name="master_id"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn track master!',
                  },
                ]}
              >
                <Select optionFilterProp="children">
                  {trackMasterList &&
                    trackMasterList.length > 0 &&
                    trackMasterList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Giá" require={false} />}
                name="price"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  placeholder="0"
                />
              </Form.Item>

              <Form.Item
                label={
                  <FormLabel
                    label="Chọn lớp học theo lộ trình"
                    require={false}
                  />
                }
                name="class_ids"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  // onChange={handleChange}
                >
                  {classes &&
                    classes.length > 0 &&
                    classes.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="" name="avatar">
                <Avatar
                  size={200}
                  src={avatar !== '' ? avatar : DefaultAvatar}
                />
              </Form.Item>

              <div>
                <Button.Default
                  style={{ fontSize: '14px' }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Chọn ảnh lộ trình
                </Button.Default>
                <p>
                  <em>
                    Kích thước ảnh: 420x300px (tỉ lê 1.4). Định dạng jpg hoặc
                    png.
                  </em>
                </p>
              </div>
            </Col>
          </Row>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setAvatar}
        />
      )}
    </>
  );
};
export default CreateTrackContent;
