import Link from 'libraries/components/commons/Link';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { ReactElement, useMemo } from 'react';
import ReactExport from 'react-data-export';
import { BonusDetails } from 'models/report.model';
import { ListPayReceiptType, ReceiptOrderReport } from 'models/report.model';

interface PropsType {
  name: string;
  receipts: ListPayReceiptType;
  firstDay: string;
  lastDay: string;
  bonuses: BonusDetails[];
  basePercentage: number;
  isError: boolean;
  isLoading: boolean;
  isBpError: boolean;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportSaleExcel = ({
  name,
  receipts,
  firstDay,
  lastDay,
  bonuses,
  basePercentage,
  isError,
  isLoading,
  isBpError,
}: PropsType): ReactElement => {
  let paidAmount = 0;
  let refundAmount = 0;
  const approvedBonuses = useMemo(() => {
    if (!bonuses || !bonuses.length) return [];
    return (
      bonuses.filter((bonus) => bonus.status === 'approve') ??
      ([] as BonusDetails[])
    );
  }, [bonuses]);
  const receiptsFromOrder = useMemo(() => {
    if (Object.keys(receipts).length === 0) return [];
    return (
      receipts.receipt_from_order.filter(({ receiver_full_name, amount }) => {
        if (receiver_full_name !== name) return false;

        paidAmount += amount || 0;
        return true;
      }) ?? []
    );
  }, [receipts]);
  const payForOrder = useMemo(() => {
    if (Object.keys(receipts).length === 0) return [];
    return (
      receipts?.pay_for_order.map((item) => {
        refundAmount -= item.amount;
        return {
          ...item,
          amount: -item.amount,
        };
      }) ?? []
    );
  }, [receipts]);

  const salary = (paidAmount * (basePercentage ?? 1)) / 100;
  const bonusTotal =
    approvedBonuses?.reduce((prev, cur) => (prev += cur.bonus), 0) ?? 0;
  return (
    <ExcelFile
      element={
        <Link.Primary
          to="#"
          style={{
            height: 32,
            lineHeight: 'normal',
            pointerEvents:
              !isError && !isLoading && !isBpError ? 'all' : 'none',
          }}
        >
          Xuất Excel
        </Link.Primary>
      }
      filename={`Báo cáo chi tiết thu nhập của nhân viên bán hàng ${name} từ ${firstDay} đến ${lastDay}`}
    >
      <ExcelSheet data={receiptsFromOrder} name="Lương">
        <ExcelColumn label="Mã đơn hàng" value="order_id" />
        <ExcelColumn
          label="Ngày giao dịch"
          value={(col: ReceiptOrderReport) =>
            col.datetime !== '' ? formatDate(col.datetime) : ''
          }
        />
        <ExcelColumn
          label="Items"
          value={(col: ReceiptOrderReport) =>
            col.items.length !== 0 ? col.items.join(',') : ''
          }
        />
        <ExcelColumn label="Người nôp tiền " value="full_name" />
        <ExcelColumn
          label="Số tiền đã đóng (đ)"
          value={(col: ReceiptOrderReport) =>
            col.amount !== 0 ? col.amount : 0
          }
        />
        <ExcelColumn
          label="Tổng đơn hàng (đ)"
          value={(col: ReceiptOrderReport) => (col.total !== 0 ? col.total : 0)}
        />
        <ExcelColumn label="Ghi chú" value="note" />
      </ExcelSheet>
      <ExcelSheet data={payForOrder} name="Tổng tiền hoàn">
        <ExcelColumn label="Mã đơn hàng" value="order_id" />
        <ExcelColumn
          label="Ngày giao dịch"
          value={(col: ReceiptOrderReport) =>
            col.datetime !== '' ? formatDate(col.datetime) : ''
          }
        />
        <ExcelColumn label="Người nôp tiền " value="full_name" />

        <ExcelColumn label="Học viên" value="receiver_full_name" />
        <ExcelColumn
          label="Số tiền"
          value={(col: ReceiptOrderReport) =>
            col.amount !== 0 ? col.amount : 0
          }
        />
        <ExcelColumn label="Ghi chú" value="note" />
      </ExcelSheet>
      <ExcelSheet data={approvedBonuses} name="Lương thưởng ">
        <ExcelColumn
          label="Ngày thực hiện"
          value={(col: BonusDetails) =>
            col.date !== '' ? formatDate(col.date) : ''
          }
        />
        <ExcelColumn label="Nội dung" value="content" />
        <ExcelColumn
          label="Lương thưởng (đ)"
          value={(col: BonusDetails) => (col.bonus !== 0 ? col.bonus : 0)}
        />
      </ExcelSheet>
      <ExcelSheet data={Array(1).fill(null)} name="Tổng">
        <ExcelColumn label="Tổng lương" value={() => salary} />
        <ExcelColumn label="Tổng thưởng" value={() => bonusTotal} />
        <ExcelColumn label="Tổng tiền hoàn" value={() => refundAmount} />
        <ExcelColumn
          label="Số dư"
          value={() => bonusTotal + salary + refundAmount}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportSaleExcel;
