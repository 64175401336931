import { UserWithRole } from 'models/user.model';
import { ReactElement } from 'react';
import { Card, Col, Row, Table } from 'antd';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { route } from 'routers/routeNames';
import KpiSaleCartTitle from './KpiSaleCartTitle';
import styled from 'styled-components';
import Button from 'libraries/components/commons/Button';
import { ListApiSaleType } from 'models/report.model';
import getKpiSale from 'libraries/utils/getKpiSale';

interface PropsType {
  salers: UserWithRole[];
}
const CartStyle = styled(Card)`
  .ant-card-head {
    background-color: #f2f2f2;
  }
  h3 {
    text-align: center;
  }
  .cart-content-title {
    min-width: 155px;
  }
  .color_blue {
    color: #0e186f;
  }
  .color_red {
    color: red;
  }
`;
const ButtonCenter = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  button {
    height: 36px;
    padding: 5px 20px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
`;
const KpiSaleCart = ({ data }: any): ReactElement => {
  return (
    <div
      className="site-card-border-less-wrapper"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '24px',

        marginTop: '24px',
      }}
    >
      {/* <Row gutter={[16, 16]}> */}
      {data.map((element: any) => {
        return (
          // <Col xs={{ span: 6 }} key={element.sale_id}>
          <CartStyle
            title={<KpiSaleCartTitle data={element} />}
            bordered={true}
            style={{ width: 315 }}
            key={element.sale_id}
          >
            <h3>{element.full_name}</h3>
            <InfoContainer>
              <p className="cart-content-title">Đơn hàng /Doanh thu:</p>
              <p className="color_blue">{`${
                element.total_orders
              }/${element.finance.toLocaleString('vi')} ₫`}</p>
            </InfoContainer>
            <InfoContainer>
              <p className="cart-content-title">Doanh thu:</p>
              <p className="color_blue">{`${element.other_finance.toLocaleString(
                'vi',
              )} ₫`}</p>
            </InfoContainer>
            <InfoContainer>
              <p className="cart-content-title">Số tiền hoàn:</p>
              <p className="color_red">{`${element.refunds.toLocaleString(
                'vi',
              )} ₫`}</p>
            </InfoContainer>
            <InfoContainer>
              <p className="cart-content-title">Bài viết / View :</p>
              <p className="color_blue">{`${element.total_posts}/${element.total_views}`}</p>
            </InfoContainer>
            <InfoContainer>
              <p
                className="cart-content-title"
                style={{ fontSize: '20px', fontWeight: '600' }}
              >
                KPI :
              </p>
              <p
                className="color_blue"
                style={{ fontSize: '20px', fontWeight: '600' }}
              >
                {getKpiSale(element.finance, element.total_views)}
              </p>
            </InfoContainer>

            <RouterLink
              to={generatePath(route.report.sale.detail, {
                saleId: element.sale_id,
              })}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              <ButtonCenter>
                <Button.Primary>Xem Thêm</Button.Primary>
              </ButtonCenter>
            </RouterLink>
          </CartStyle>
          // </Col>
        );
      })}
      {/* </Row> */}
    </div>
  );
};
export default KpiSaleCart;
