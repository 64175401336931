import { TableOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form as FormAntd, InputNumber } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { styleForm } from './YoutubeLinkBtn';
interface ITableBtn {
  handleTable: (row: number, column: number) => void;
}
const Form = styled(FormAntd)`
  & .ant-form-item-explain.ant-form-item-explain-error,
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    display: none;
  }
`;
const TableButton = ({ handleTable }: ITableBtn) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const { column, row } = values;
    form.validateFields().then(() => handleTable(row, column));
    setVisible(false);
  };
  // const handleKeyUp = (e: any) => {
  //   if (e.code === 'Enter') form.submit();
  // };
  return (
    <Dropdown
      destroyPopupOnHide={true}
      trigger={['click']}
      visible={visible}
      onVisibleChange={(flag) => setVisible(flag)}
      overlay={
        <Form
          labelCol={{
            span: 9,
          }}
          onFinish={onFinish}
          form={form}
          name="table"
          initialValues={{
            column: 3,
            row: 3,
          }}
          style={styleForm}
          // onKeyUp={handleKeyUp}
        >
          <Form.Item
            rules={[
              () => ({
                validator(_: any, value: any) {
                  if (value <= 12 && value > 0) return Promise.resolve();
                  return Promise.reject();
                },
              }),
            ]}
            style={{
              padding: 0,
              margin: 0,
            }}
            name="column"
            label="column"
          >
            <InputNumber keyboard={true} autoFocus />
          </Form.Item>
          <Form.Item
            style={{
              padding: 0,
              margin: 0,
            }}
            name="row"
            label="row"
            rules={[
              () => ({
                validator(_: any, value: any) {
                  if (value <= 12 && value > 0) return Promise.resolve();
                  return Promise.reject();
                },
              }),
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            style={{
              padding: 0,
              margin: '8px 0',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
              }}
            >
              Enter
            </Button>
          </Form.Item>
        </Form>
      }
    >
      <Button title="Bảng" icon={<TableOutlined />} type="text" />
    </Dropdown>
  );
};
export default TableButton;
