import { Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableContainer from 'components/table/TableContainer';
import Span from 'libraries/components/commons/Span';
import { userRoleValues } from 'libraries/utils/globalVariable';
import { formatDate } from 'libraries/utils/timeDistanceToNow';
import { UsersType } from 'models/user.model';
import { ReactElement, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { route } from 'routers/routeNames';
import { selectUsers } from 'store/user.slice';

interface KeySearchType {
  keyword: string;
  status: string;
  role: string;
}

interface PropsType {
  state: KeySearchType;
  handleSetState: (field: string, value: string) => void;
  setKeySearch: (value: string) => void;
}

const { Option } = Select;

const TeacherTable = ({
  state,
  handleSetState,
  setKeySearch,
}: PropsType): ReactElement => {
  const users = useSelector(selectUsers);
  const listTeacher: any = users.filter((item) => {
    return item.roles?.includes(3) === true;
  });

  const { status, role } = state;

  const [tmpName, setTmpName] = useState('');
  const [tmpEmail, setTmpEmail] = useState('');
  const [tmpPhone, setTmpPhone] = useState('');
  const columns: ColumnsType<UsersType> = [
    {
      title: 'Họ tên',
      children: [
        {
          dataIndex: 'full_name',
          key: 'full_name',
          title: (
            <Input
              value={tmpName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyword', tmpName);
                }
              }}
              onChange={(e) => {
                setTmpName(e.target.value);
                setTmpEmail('');
                setTmpPhone('');
              }}
            />
          ),
          render: (_text, item) => (
            <RouterLink to={generatePath(route.user.info, { userId: item.id })}>
              {item.full_name}
            </RouterLink>
          ),
        },
      ],
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      showSorterTooltip: false,
      width: '22%',
    },
    {
      width: '23%',
      title: 'Email',
      children: [
        {
          key: 'email',
          dataIndex: 'email',
          title: (
            <Input
              value={tmpEmail}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyword', tmpEmail);
                }
              }}
              onChange={(e) => {
                setTmpName('');
                setTmpEmail(e.target.value);
                setTmpPhone('');
              }}
            />
          ),
          render: (email) => <a href={`mailto:${email}`}>{email}</a>,
        },
      ],
      // TODO: lỗi sort email
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
    },
    {
      width: '15%',
      title: 'Số điện thoại',
      children: [
        {
          key: 'phone',
          dataIndex: 'phone',
          title: (
            <Input
              value={tmpPhone}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSetState('keyword', tmpPhone);
                }
              }}
              onChange={(e) => {
                setTmpName('');
                setTmpEmail('');
                setTmpPhone(e.target.value);
              }}
            />
          ),
          render: (phone) => <a href={`tel:${phone}`}>{phone}</a>,
        },
      ],
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      showSorterTooltip: false,
    },
    {
      width: '20%',
      title: 'CV',
      children: [
        {
          key: 'cv',
          dataIndex: 'link_cv',

          render: (link_cv) => {
            if (link_cv !== '') {
              return (
                <a href={link_cv} target="_blank" rel="noreferrer">
                  Xem CV
                </a>
              );
            } else {
              return <p style={{ color: 'red' }}>Chưa cập nhật</p>;
            }
          },
        },
      ],
    },
    {
      width: '10%',
      title: 'Quyền',
      children: [
        {
          key: 'roles',
          dataIndex: 'roles',
          render: (_text, item) => {
            const result = [];
            for (let i = 0; i < item.roles.length; i++) {
              const role = item.roles[i];
              for (let j = 0; j < userRoleValues.length; j++) {
                const item = userRoleValues[j];
                if (item.value === role) {
                  result.push(item.label);
                  break;
                }
              }
            }
            return (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {result.length > 0 &&
                  result.map((item, index) => (
                    <Span key={index} className="default">
                      {item}
                    </Span>
                  ))}
              </div>
            );
          },
        },
      ],
    },
    {
      width: '15%',
      title: 'Trạng thái',
      children: [
        {
          key: 'status',
          dataIndex: 'status',
          title: (
            <Select
              dropdownMatchSelectWidth={200}
              value={status}
              onChange={(value) => handleSetState('status', value)}
              onClick={(e) => e.stopPropagation()}
            >
              <Option value="">Tất cả</Option>
              <Option value="true">Kích hoạt</Option>
              <Option value="value">Vô hiệu hoá</Option>
            </Select>
          ),
          render: (status) => (status ? 'Kích hoạt' : 'Vô hiệu hoá'),
        },
      ],
    },
    {
      width: '10%',
      title: 'Ngày tạo',
      children: [
        {
          key: 'created_at',
          dataIndex: 'created_at',
          render: (created_at) =>
            created_at !== '' ? formatDate(created_at, 'dd-MM-yyyy') : '',
        },
      ],
    },
  ];

  useEffect(() => {
    const { keyword, status, role } = state;

    setKeySearch(`?keyword=${keyword}&status=${status}&role=3`);
  }, [state]);

  return (
    <TableContainer>
      <Table<UsersType>
        columns={columns}
        dataSource={listTeacher}
        pagination={{ showSizeChanger: false }}
        rowKey="id"
        bordered
      />
    </TableContainer>
  );
};
export default TeacherTable;
