import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrack, setKeySearch } from 'store/track.slice';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { route } from 'routers/routeNames';
import { Col, Input, InputNumber, message, Row, Select } from 'antd';
import styled from 'styled-components';
import { CopyOutlined, DeleteFilled, LeftOutlined } from '@ant-design/icons';

import Link from 'libraries/components/commons/Link';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Breadcrumb from 'components/commons/Breadcrumb';

import Form from 'libraries/components/form/Form';
import FormLabel from 'libraries/components/form/FormLabel';
import MediaManagement from 'libraries/components/media/MediaManagement';
import Button from 'libraries/components/commons/Button';
import { trackStatusValues } from 'libraries/utils/globalVariable';
import {
  useGetClassesListQuery,
  useGetTrackMasterQuery,
  useUpdateTrackMutation,
  useDeleteTrackMutation,
} from 'services/track.service';
import { TrackMaster } from 'models/track.model';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';

const { Option } = Select;

const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const TrackDetailContent = (): ReactElement => {
  const { trackId } = useParams<{ trackId: string }>();
  const track = useSelector(selectTrack);
  const { data: classList } = useGetClassesListQuery();
  const { data } = useGetTrackMasterQuery();

  const [updateTrack] = useUpdateTrackMutation();
  const [deleteTrack] = useDeleteTrackMutation();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [tmpThumbnail, setTmpThumbnail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const { name, thumbnail, master_id, class_ids } = track;
  const trackMasterList = data ? data : ([] as TrackMaster[]);
  const trackMasterCurrent = trackMasterList.filter(
    (item) => item.id === master_id,
  );

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
    setIsChange(true);
  };

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    if (values.master_id === trackMasterCurrent[0].name) {
      values.master_id = trackMasterCurrent[0].id;
    }
    updateTrack({ data: values, trackId: trackId })
      .unwrap()
      .then(() => message.success('Cập nhật lộ trình thành công!'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleDelete = () => {
    deleteTrack({ trackId: trackId })
      .unwrap()
      .then(() => {
        message.success('Xoá lộ trình thành công!');
        setTimeout(() => {
          history.push(generatePath(route.track.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleCopy = () => {
    console.log(track);
  };

  const handleClick = () => {
    dispatch(setKeySearch(`keyword=&master_id=`));
  };

  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    onFinish(form.getFieldsValue());
  };

  return (
    <>
      <Breadcrumb
        secondaryLink={generatePath(route.track.list)}
        secondaryText="Lộ trình"
        text={track.name}
      />
      <Hotkeys
        keyName="ctrl+s,command+s"
        // eslint-disable-next-line
        onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
        // eslint-disable-next-line
        filter={(event) => true}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onFieldsChange={() => {
            setIsChange(true);
          }}
          initialValues={{
            ...track,
            track_name: name,
            class_ids: class_ids,
          }}
        >
          <GroupButton style={{ display: 'flex', flexWrap: 'wrap' }}>
            <LinksContainer>
              <Link.Default
                to={generatePath(route.track.list)}
                onClick={handleClick}
              >
                <LeftOutlined /> Quay lại
              </Link.Default>
            </LinksContainer>

            <LinksContainer>
              <Link.Success to="#" onClick={handleCopy}>
                <CopyOutlined /> Copy lộ trình
              </Link.Success>
              <Link.Danger to="#" onClick={handleDelete}>
                <DeleteFilled /> Xoá lộ trình
              </Link.Danger>
            </LinksContainer>
          </GroupButton>

          <Row gutter={[30, 30]}>
            <Col xs={24} md={16}>
              <Form.Item
                label={<FormLabel label="Tên" />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên lộ trình!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Giới thiệu" require={false} />}
                name="description"
              >
                <Editor
                  value={'description'}
                  height={250}
                  onChange={(value) => handleChangeDescription(value)}
                ></Editor>
              </Form.Item>

              <Button.Action
                style={{ fontSize: '14px' }}
                htmlType="submit"
                disabled={!isChange}
              >
                Lưu
              </Button.Action>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label={<FormLabel label="Trạng thái" require={false} />}
                name="status"
              >
                <Select optionFilterProp="children">
                  {trackStatusValues.map((item, index) => (
                    <Option value={item.value.toString()} key={index}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Chọn track master" require={true} />}
                name="master_id"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng chọn track master!',
                  },
                ]}
              >
                <Select optionFilterProp="children">
                  {trackMasterList &&
                    trackMasterList.length > 0 &&
                    trackMasterList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label={<FormLabel label="Giá" require={false} />}
                name="price"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </Form.Item>

              {classList && (
                <Form.Item
                  label={
                    <FormLabel label="Lớp học trong lộ trình" require={true} />
                  }
                  name="class_ids"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Chọn lớp học lộ trình"
                  >
                    {classList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item label="" name="thumbnail">
                {(tmpThumbnail !== '' || thumbnail !== '') && (
                  <img
                    style={{ width: '100%' }}
                    src={tmpThumbnail !== '' ? tmpThumbnail : thumbnail}
                  />
                )}
              </Form.Item>

              <Form.Item>
                <Button.Default
                  style={{ fontSize: '14px' }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Chọn ảnh lộ trình
                </Button.Default>

                <p>
                  <em>
                    Kích thước ảnh: 420x300px (tỉ lê 1.4). Định dạng jpg hoặc
                    png.
                  </em>
                </p>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Hotkeys>
      {isModalVisible && (
        <MediaManagement
          isModalVisible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          getUrl={setTmpThumbnail}
        />
      )}
    </>
  );
};
export default TrackDetailContent;
