import { BorderBoxContent } from 'components/commons/ComponentContent';
import { ReactElement, useState } from 'react';
import Form from 'libraries/components/form/Form';
import { Input, Col, Row, message, Select } from 'antd';
import FormLabel from 'libraries/components/form/FormLabel';
import Button from 'libraries/components/commons/Button';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  useDeleteTrackMasterMutation,
  useUpdateTrackMasterMutation,
} from 'services/trackmaster.service';
import { UserWithRole } from 'models/user.model';
import { TrackMaster } from 'models/trackmaster.model';
import LinksContainer from 'libraries/components/commons/LinksContainer';
import Link from 'libraries/components/commons/Link';
import { DeleteFilled, LeftOutlined } from '@ant-design/icons';
import { route } from 'routers/routeNames';
import styled from 'styled-components';
import Editor from 'libraries/editor';
import Hotkeys from 'react-hot-keys';

interface TrackMasterContent {
  trackMasterInfo: TrackMaster;
  salers: UserWithRole[];
}

const { Option } = Select;
const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const TrackMasterContent = ({
  trackMasterInfo,
  salers,
}: TrackMasterContent): ReactElement => {
  const [form] = Form.useForm();
  const [updateTrackMaster] = useUpdateTrackMasterMutation();
  const [isChange, setIsChange] = useState(false);
  const { trackMasterId } = useParams<{ trackMasterId: string }>();
  const [deleteTrackMaster] = useDeleteTrackMasterMutation();
  const history = useHistory();

  const handleChangeDescription = (value: string) => {
    form.setFieldsValue({
      description: value,
    });
    setIsChange(true);
  };

  // eslint-disable-next-line
  const onFinish = (values: any) => {
    updateTrackMaster({ trackMasterId: trackMasterId, data: values })
      .unwrap()
      .then(() => message.success('Cập nhật track master thành công!'))
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handleClick = () => {
    console.log('back');
  };

  const handleDelete = () => {
    deleteTrackMaster(trackMasterId)
      .unwrap()
      .then(() => {
        message.success('Xoá track master thành công!');
        setTimeout(() => {
          history.push(generatePath(route.track.list));
        }, 700);
      })
      .catch((error) => {
        message.error(error.data);
      });
  };

  const handlersHotkeys = (e: KeyboardEvent) => {
    e.preventDefault();
    onFinish(form.getFieldsValue());
  };

  return (
    <Hotkeys
      keyName="ctrl+s,command+s"
      // eslint-disable-next-line
      onKeyDown={(_, event: KeyboardEvent) => handlersHotkeys(event)}
      // eslint-disable-next-line
      filter={(event) => true}
    >
      <GroupButton>
        <LinksContainer>
          <Link.Default
            to={generatePath(route.track.list)}
            onClick={handleClick}
          >
            <LeftOutlined /> Quay lại
          </Link.Default>
        </LinksContainer>

        <LinksContainer>
          <Link.Danger to="#" onClick={handleDelete}>
            <DeleteFilled /> Xoá track master
          </Link.Danger>
        </LinksContainer>
      </GroupButton>

      <BorderBoxContent>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...trackMasterInfo,
            sale: trackMasterInfo.saleId,
          }}
          onFieldsChange={() => {
            setIsChange(true);
          }}
          onFinish={onFinish}
        >
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={<FormLabel label="Tên track master" require={true} />}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Xin vui lòng nhập tên lộ trình!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={<FormLabel label="Nhân viên sale" require={false} />}
                name="sale"
              >
                {/* <Input disabled /> */}
                <Select optionFilterProp="children">
                  {salers.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.full_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label={<FormLabel label="Mô tả track master" require={false} />}
                name="description"
              >
                <Editor
                  value={'description'}
                  height={250}
                  onChange={(value) => handleChangeDescription(value)}
                ></Editor>
              </Form.Item>
            </Col>
          </Row>

          <Button.Action
            style={{ fontSize: '14px' }}
            htmlType="submit"
            disabled={!isChange}
          >
            Lưu
          </Button.Action>
        </Form>
      </BorderBoxContent>
    </Hotkeys>
  );
};

export default TrackMasterContent;
